import axios from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { CALENDAR_SERVICE_BASE_URL } from '@lyrahealth-inc/shared-app-logic'

import { GET_CALENDAR_EVENTS, GET_CALENDAR_OAUTH_URL, GET_CALENDAR_TOKEN } from '../../common/constants/reduxConstants'
import store from '../../data/store'

type OAuthURLRequest = {
  providerEmail: string
  providerId: string
  timeZone: string
}

const calendarServiceAxiosInstance = axios.create({
  baseURL: CALENDAR_SERVICE_BASE_URL,
})

calendarServiceAxiosInstance.interceptors.request.use(function (config) {
  const token = store.getState().get('auth')?.apiToken
  if (token && config.headers) {
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
})

export const getOAuthURL = (params: OAuthURLRequest) => async (dispatch: Dispatch<AnyAction>) => {
  const { data } = await calendarServiceAxiosInstance.get('/oauth2/google', {
    params: {
      provider_email: params.providerEmail,
      provider_id: params.providerId,
      timezone: params.timeZone,
    },
  })

  return dispatch({
    type: GET_CALENDAR_OAUTH_URL,
    data: data.authorization_uri,
  })
}

export const getCalendarToken =
  ({ providerId }: { providerId: string }) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await calendarServiceAxiosInstance.get(`/api/v1/auth/${providerId}/blah`)
    return dispatch({
      type: GET_CALENDAR_TOKEN,
      data: data.access_token,
    })
  }

export const getCalendarEvents =
  ({ providerId, startDate, endDate }: { providerId: string; startDate: string; endDate: string }) =>
  async (dispatch: Dispatch<AnyAction>) => {
    const { data } = await calendarServiceAxiosInstance.get(`/api/v1/events`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        provider_id: providerId,
      },
    })
    return dispatch({
      type: GET_CALENDAR_EVENTS,
      data: data,
    })
  }
