import React, { FunctionComponent } from 'react'
import Svg, { G, Mask, Path } from 'react-native-svg'

type ValuePropMeditationsArticlesVideos = {
  width?: number
  height?: number
}

export const ValuePropMeditationsArticlesVideos: FunctionComponent<ValuePropMeditationsArticlesVideos> = ({
  width = 57,
  height = 56,
}) => {
  return (
    <Svg width={width} height={height} viewBox='0 0 57 56' fill='none'>
      <Path
        d='M28.2105 0.19458C24.3451 0.19458 20.5947 0.857446 17.0635 2.16437C8.65652 5.28061 2.74606 12.1892 0.847094 21.1186C-0.407814 26.9405 -0.247354 32.3253 1.34065 37.5729C3.36798 44.2746 8.34445 49.9261 14.9941 53.0778C18.2952 54.6459 22.0876 55.5411 26.264 55.7381C26.8007 55.7624 27.3462 55.7757 27.8885 55.7757C31.2393 55.7757 34.6101 55.3165 37.9078 54.4113C38.1612 54.3482 38.4025 54.2829 38.657 54.2076C41.6205 53.34 44.2875 51.9678 46.5693 50.1342C49.0471 48.1544 51.0866 45.6446 52.6248 42.6789C52.928 42.0946 53.2179 41.4793 53.5377 40.7434C55.2973 36.6577 56.1472 32.0841 55.9967 27.5115C55.9258 25.4897 55.6248 23.3407 55.1047 21.1186C54.2482 17.5132 52.8516 14.1225 51.0655 11.3073C50.5764 10.5514 50.1271 9.91624 49.6823 9.34523C46.472 5.20425 41.47 2.29827 35.2221 0.945976C32.9269 0.447996 30.5676 0.195687 28.2116 0.195687L28.2105 0.19458Z'
        fill='white'
      />
      <Mask
        id='mask0_10302_10786'
        // @ts-ignore maskType not supported in react-native-svg
        style={{
          maskType: 'luminance',
        }}
        maskUnits='userSpaceOnUse'
        x={0}
        y={0}
        width={57}
        height={56}
      >
        <Path
          d='M28.2105 0.19458C24.3451 0.19458 20.5947 0.857446 17.0635 2.16437C8.65652 5.28061 2.74606 12.1892 0.847094 21.1186C-0.407814 26.9405 -0.247354 32.3253 1.34065 37.5729C3.36798 44.2746 8.34445 49.9261 14.9941 53.0778C18.2952 54.6459 22.0876 55.5411 26.264 55.7381C26.8007 55.7624 27.3462 55.7757 27.8885 55.7757C31.2393 55.7757 34.6101 55.3165 37.9078 54.4113C38.1612 54.3482 38.4025 54.2829 38.657 54.2076C41.6205 53.34 44.2875 51.9678 46.5693 50.1342C49.0471 48.1544 51.0866 45.6446 52.6248 42.6789C52.928 42.0946 53.2179 41.4793 53.5377 40.7434C55.2973 36.6577 56.1472 32.0841 55.9967 27.5115C55.9258 25.4897 55.6248 23.3407 55.1047 21.1186C54.2482 17.5132 52.8516 14.1225 51.0655 11.3073C50.5764 10.5514 50.1271 9.91625 49.6823 9.34523C46.472 5.20425 41.47 2.29827 35.2221 0.945976C32.9269 0.447996 30.5676 0.195687 28.2116 0.195687L28.2105 0.19458Z'
          fill='white'
        />
      </Mask>
      <G mask='url(#mask0_10302_10786)'>
        <Path
          d='M28.3397 30.6932C28.5179 28.889 28.9657 27.5193 29.1901 26.9868C29.0526 27.3125 27.6345 26.8031 27.2934 26.7107C25.7114 30.152 25.6828 40.742 26.2912 44.4804C26.7291 44.5772 28.0416 44.6586 28.4838 44.7312C27.9348 42.082 27.5036 39.171 28.3397 30.6932Z'
          fill='#127719'
        />
        <Path
          d='M32.0344 33.9154C28.6591 36.141 27.4148 39.8936 27.2355 41.1467C27.2201 41.2556 27.2355 41.4063 27.3455 41.4206C27.3906 41.4272 27.4302 41.3931 27.4742 41.381C30.0717 40.721 34.205 39.0091 38.4351 31.8856C38.4725 31.8229 38.5396 31.7503 38.4879 31.6986C38.3438 31.7217 35.8343 31.4093 32.0333 33.9143L32.0344 33.9154Z'
          fill='#127719'
        />
        <Path
          d='M20.4306 33.3039C22.2415 35.8772 25.5397 38.1743 26.224 37.5978C27.0392 36.9113 25.2911 33.7715 24.9963 33.3721C21.3636 28.4445 18.6066 27.944 18.2666 28.1101C17.5317 28.4709 19.8685 32.5052 20.4306 33.3039Z'
          fill='#127719'
        />
        <Path
          d='M26.9534 25.7952C26.5827 25.7919 26.4067 25.7435 26.377 25.4442C26.663 25.3584 26.7543 25.5421 26.9534 25.7952Z'
          fill='#FFF5DB'
        />
        <Path
          d='M27.581 26.2706C27.3885 26.2805 27.2004 26.2849 27.1641 26.0176C27.3566 25.8471 27.4468 26.0209 27.5557 26.1298C27.5975 26.154 27.6151 26.1837 27.6085 26.2167C27.6019 26.2497 27.5931 26.2673 27.581 26.2706Z'
          fill='#FFECB9'
        />
        <Path
          d='M22.361 21.9954C21.7702 20.5762 21.4325 19.1041 22.1124 17.7015C22.7504 16.3857 23.6933 15.3196 25.0861 14.7772C26.2775 14.313 27.7957 14.368 28.6484 15.3218C29.5373 14.3097 31.0962 13.9532 32.3372 14.478C33.5782 15.0028 34.4077 16.3681 34.3021 17.7114C35.202 17.4231 36.2328 17.9028 36.751 18.6916C37.8776 20.4056 37.3825 23.0064 36.356 24.6192C35.1789 26.4686 33.1975 27.7349 31.0225 28.0132C28.633 28.3201 24.9343 28.1606 23.0926 26.3729C22.295 25.5984 21.7823 24.5631 21.5205 23.4828C21.4369 23.1373 21.3797 22.7644 21.5128 22.4354C21.6459 22.1065 22.0342 21.8578 22.3621 21.9954H22.361Z'
          fill='#F47352'
        />
        <Path
          d='M29.6938 17.6399C30.389 17.3825 31.2153 17.8225 31.619 18.4672C32.3044 19.5608 30.9677 20.8853 29.8654 20.3573C29.1525 20.0151 28.5727 18.7037 29.1646 18.0172C29.323 17.8335 29.5034 17.7114 29.6927 17.641L29.6938 17.6399Z'
          fill='#FFD974'
        />
        <Path
          d='M23.9305 68.0555C48.746 68.0555 68.863 62.6708 68.863 56.0286C68.863 49.3863 48.746 44.0017 23.9305 44.0017C-0.885004 44.0017 -21.002 49.3863 -21.002 56.0286C-21.002 62.6708 -0.885004 68.0555 23.9305 68.0555Z'
          fill='#5DAD3E'
        />
      </G>
    </Svg>
  )
}
