import React, { MutableRefObject, useMemo } from 'react'
import { MessageDescriptor } from 'react-intl'
import { ImageSourcePropType } from 'react-native'
import { connect, useSelector } from 'react-redux'

import { AnyAction, bindActionCreators, Dispatch } from 'redux'
import styled, { useTheme } from 'styled-components/native'

import { AIDraftStatus, AppointmentSummary } from '@lyrahealth-inc/shared-app-logic'
import {
  BodyText,
  BodyTextSize,
  ButtonModifier,
  CloseIcon,
  Image,
  LoadingSpinner,
  PressableOpacity,
  PrimaryButton,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
  useFetcher,
} from '@lyrahealth-inc/ui-core-crossplatform'
import noNewActivities from '@lyrahealth-inc/ui-core-crossplatform/src/assets/noNewActivities.png'

import { getFeedback } from './data/clientNotesAutoActions'
import PanelFeedbackSection from './PanelFeedbackSection'
import { actions, mixpanelEvents } from '../../../../mixpanel/mixpanelConstants'
import { track } from '../../../../mixpanel/mixpanelTracking'
import { getAuthUserId } from '../../data/auth/authSelectors'

const Container = styled.View({
  overflow: 'auto',
  height: '100%',
})

const HeaderContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: `0 ${theme.spacing['16px']} 10px ${theme.spacing['16px']}`,
  border: 0,
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.borderDefault,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  minHeight: '59px',
}))

const CloseIconContainer = styled(PressableOpacity)<{ theme: ThemeType }>(({ theme }) => ({
  paddingTop: theme.spacing['8px'],
}))

const SubsectionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.spacing['16px'],
}))

const ImageContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: theme.spacing['24px'],
}))

const ImageHeader = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing['8px'],
}))

const ImageText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing['8px'],
  maxWidth: '280px',
  marginLeft: 'auto',
  marginRight: 'auto',
}))

const ProcessingImage = styled(Image)({
  width: '226px',
  height: '174px',
})

const SummaryContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.spacing['16px'],
  borderRadius: theme.spacing['16px'],
  background: theme.colors.components.aiTab.aiTabPanel.background,
}))

const InsertButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingTop: theme.spacing['12px'],
  marginLeft: 'auto',
  marginRight: 'auto',
}))

const summaryPromptTypes: { [key: string]: string } = {
  presentingProblem: 'PROMPT_FOR_PRESENTING_ISSUES_V1',
  symptomDescription: 'PROMPT_FOR_SESSION_SUMMARY_V1',
}

const ClientNotesPanel: React.FC<ClientNotesPanelProps> = ({
  appointmentId,
  fieldName,
  feedbackFormRef,
  noteAppointmentSummary,
  noteId,
  noteRef,
  noteType,
  panelTitle,
  setIsPanelOpen,
  actions: { getFeedback },
}) => {
  const { colors } = useTheme()

  const userId: string = useSelector(getAuthUserId)
  const [isLoadingFeedback, , hasFetchedFeedback] = useFetcher(
    [getFeedback, { providerId: userId, entityId: noteId }],
    [userId, noteId],
  )

  const status: AIDraftStatus = noteAppointmentSummary.status

  const summary: string | null = useMemo(() => {
    if (status === AIDraftStatus.COMPLETE && fieldName in summaryPromptTypes) {
      const fieldSummary = noteAppointmentSummary?.summary?.find(({ prompt_type }: { prompt_type: string }) => {
        return summaryPromptTypes[fieldName] === prompt_type
      })
      if (fieldSummary) {
        return fieldSummary.summary_text
      }
    }
    return null
  }, [fieldName, noteAppointmentSummary, status])

  const closePanel = () => {
    setIsPanelOpen()
    feedbackFormRef.current?.restart()
  }

  const renderDraft = () => {
    if ([AIDraftStatus.IN_PROGRESS, AIDraftStatus.NOT_STARTED].includes(status)) {
      return (
        <SubsectionContainer>
          <ImageContainer>
            <ProcessingImage source={noNewActivities as ImageSourcePropType} />
          </ImageContainer>
          <ImageHeader size={SubheadSize.SMALL} text='Draft not ready yet' color={colors.textPrimary} />
          <ImageText
            testID={tID('ClientNotesPanel-draftNotReadyText')}
            text='We’re still processing your draft. When it’s ready you can find it here'
            size={BodyTextSize.SMALL}
            color={colors.textSecondary}
          />
        </SubsectionContainer>
      )
    } else if (summary) {
      return (
        <SubsectionContainer>
          <SummaryContainer>
            <BodyText
              testID={tID(`ClientNotesPanel-summaryText`)}
              text={summary}
              size={BodyTextSize.DEFAULT}
              color={colors.textSecondary}
            />
            <InsertButtonContainer>
              <PrimaryButton
                text={'Paste into note'}
                onPress={() => {
                  const initialFieldValue = noteRef.current.getFieldState(fieldName).value
                  noteRef.current.mutators.setFormAttribute(
                    fieldName,
                    initialFieldValue ? `${initialFieldValue}\n\n${summary}` : summary,
                  )
                  track({
                    event: mixpanelEvents.BUTTON_PRESS,
                    action: actions.AI_DRAFTS_PASTE_INTO_NOTE,
                    details: {
                      noteType,
                      appointmentId,
                    },
                  })
                }}
                testID={tID('ClientNotesPanel-pasteIntoNoteButton')}
                modifier={ButtonModifier.PERIWINKLE}
              />
            </InsertButtonContainer>
          </SummaryContainer>
        </SubsectionContainer>
      )
    } else {
      return null
    }
  }

  return (
    <Container>
      <HeaderContainer>
        <Subhead size={SubheadSize.SMALL} text={`Draft: ${panelTitle}`} color={colors.textPrimary} />
        <CloseIconContainer onPress={closePanel} testID={tID('InfractionPaymentModal-close')}>
          <CloseIcon size={16} />
        </CloseIconContainer>
      </HeaderContainer>
      {isLoadingFeedback || !hasFetchedFeedback ? (
        <SubsectionContainer>
          <ImageContainer>
            <LoadingSpinner size={60} />
          </ImageContainer>
          <ImageHeader size={SubheadSize.SMALL} text='Fetching draft' color={colors.textPrimary} />
          <ImageText
            testID={tID(`ClientNotesPanel-loadingDraftText`)}
            text='This should only take a second'
            size={BodyTextSize.SMALL}
            color={colors.textSecondary}
          />
        </SubsectionContainer>
      ) : (
        <>
          {renderDraft()}
          {status === AIDraftStatus.COMPLETE && noteId && (
            <PanelFeedbackSection
              fieldName={fieldName}
              feedbackFormRef={feedbackFormRef}
              noteId={noteId}
              noteType={noteType}
              appointmentId={appointmentId}
            />
          )}
        </>
      )}
    </Container>
  )
}

type ClientNotesPanelProps = {
  appointmentId: string
  feedbackFormRef: MutableRefObject<any>
  fieldName: string
  noteAppointmentSummary: AppointmentSummary
  noteId?: string
  noteRef: MutableRefObject<any>
  noteType: string
  panelTitle: MessageDescriptor | string
  setIsPanelOpen: () => void
  actions: any
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators({ getFeedback }, dispatch),
})

export default connect(null, mapDispatchToProps)(ClientNotesPanel)
