import { createReducer } from '@reduxjs/toolkit'

import { ProviderCalendarEvent } from '@lyrahealth-inc/shared-app-logic'

import { GET_CALENDAR_EVENTS, GET_CALENDAR_TOKEN } from '../../common/constants/reduxConstants'

type CalendarState = {
  calendarEvents: ProviderCalendarEvent[]
  token?: string
  requiresAuthorization?: boolean
}

const initialState = {
  isLoading: true,
  calendarEvents: [],
}

export default createReducer<CalendarState>(initialState, (builder) => {
  builder.addCase(GET_CALENDAR_TOKEN, (state, action: any) => {
    state.requiresAuthorization = action.data == null
    return state
  })
  builder.addCase(GET_CALENDAR_EVENTS, (state, action: any) => {
    state.calendarEvents = action.data
    return state
  })
})
