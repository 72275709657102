import React, { FunctionComponent } from 'react'
import { defineMessage, defineMessages, IntlShape, MessageDescriptor } from 'react-intl'

import { ThemeColors } from './theme/colors'
import { DependentTypes, FormMetadata } from '../../../models'

export enum KEYS {
  ENTER = 'Enter',
  SHIFT = 'Shift',
  CONTROL = 'Control',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ESC = 'Escape',
  META = 'Meta', // CMD key for mac,
  SPACE = ' ',
}

export const overrideCompanyNames = {
  mgb: 'MGBHP - Employees Group',
  mgbhealthplan: 'MGBHP - Commercial Plan',
}

export const DEFAULT_CARE_LANGUAGE = 'en'

// TODO: Instead of maintaining our own translations, consider replacing this with Intl.DisplayNames (i.e. `intl.formatDisplayName()`),
// if the display names are accurate.
export const CARE_LANGUAGES = defineMessages({
  ar: {
    defaultMessage: 'Arabic',
    description: 'Name of a language a provider speaks: Arabic (all variants)',
  },
  bg: {
    defaultMessage: 'Bulgarian',
    description: 'Name of a language a provider speaks: Bulgarian',
  },
  cs: {
    defaultMessage: 'Czech',
    description: 'Name of a language a provider speaks: Czech',
  },
  da: {
    defaultMessage: 'Danish',
    description: 'Name of a language a provider speaks: Danish',
  },
  de: {
    defaultMessage: 'German',
    description: 'Name of a language a provider speaks: German (all dialects)',
  },
  'de-DE': {
    defaultMessage: 'German',
    description: 'Name of a language a provider speaks: German (all dialects)',
  },
  el: {
    defaultMessage: 'Greek',
    description: 'Name of a language a provider speaks: Greek',
  },
  en: {
    defaultMessage: 'English',
    description: 'Name of a language a provider speaks: English (all dialects)',
  },
  'en-US': {
    defaultMessage: 'English',
    description: 'Name of a language a provider speaks: English (all dialects)',
  },
  es: {
    defaultMessage: 'Spanish (Spain)',
    description: 'Name of a language a provider speaks: Spanish from Spain',
  },
  'es-419': {
    defaultMessage: 'Spanish',
    description: 'Name of a language a provider speaks: Spanish',
  },
  fil: {
    defaultMessage: 'Filipino',
    description: 'Name of a language a provider speaks: Filipino',
  },
  fr: {
    defaultMessage: 'French',
    description: 'Name of a language a provider speaks: French (all dialects)',
  },
  'fr-CA': {
    defaultMessage: 'French (Canada)',
    description: 'Name of a language a provider speaks: French from Canada',
  },
  'fr-FR': {
    defaultMessage: 'French',
    description: 'Name of a language a provider speaks: French (all dialects)',
  },
  he: {
    defaultMessage: 'Hebrew',
    description: 'Name of a language a provider speaks: Hebrew',
  },
  hi: {
    defaultMessage: 'Hindi',
    description: 'Name of a language a provider speaks: Hindi',
  },
  hu: {
    defaultMessage: 'Hungarian',
    description: 'Name of a language a provider speaks: Hungarian',
  },
  id: {
    defaultMessage: 'Indonesian',
    description: 'Name of a language a provider speaks: Indonesian',
  },
  it: {
    defaultMessage: 'Italian',
    description: 'Name of a language a provider speaks: Italian (all dialects)',
  },
  jp: {
    defaultMessage: 'Japanese',
    description: 'Name of a language a provider speaks: Japanese',
  },
  ko: {
    defaultMessage: 'Korean',
    description: 'Name of a language a provider speaks: Korean',
  },
  lt: {
    defaultMessage: 'Lithuanian',
    description: 'Name of a language a provider speaks: Lithuanian',
  },
  nl: {
    defaultMessage: 'Dutch',
    description: 'Name of a language a provider speaks: Dutch',
  },
  ms: {
    defaultMessage: 'Malay',
    description: 'Name of a language a provider speaks: Malay',
  },
  cmn: {
    // NOTE: Intl.DisplayNames formats `cmn` in English as `Chinese`
    defaultMessage: 'Mandarin',
    description: 'Name of a language a provider speaks: Mandarin',
  },
  pl: {
    defaultMessage: 'Polish',
    description: 'Name of a language a provider speaks: Polish',
  },
  pt: {
    defaultMessage: 'Portuguese',
    description: 'Name of a language a provider speaks: Portuguese (all dialects)',
  },
  'pt-BR': {
    defaultMessage: 'Portuguese (Brazil)',
    description: 'Name of a language a provider speaks: Portuguese from Brazil',
  },
  ro: {
    defaultMessage: 'Romanian',
    description: 'Name of a language a provider speaks: Romanian',
  },
  ru: {
    defaultMessage: 'Russian',
    description: 'Name of a language a provider speaks: Russian',
  },
  sv: {
    defaultMessage: 'Swedish',
    description: 'Name of a language a provider speaks: Swedish',
  },
  th: {
    defaultMessage: 'Thai',
    description: 'Name of a language a provider speaks: Thai',
  },
  tr: {
    defaultMessage: 'Turkish',
    description: 'Name of a language a provider speaks: Turkish',
  },
  uk: {
    defaultMessage: 'Ukranian',
    description: 'Name of a language a provider speaks: Ukrainian',
  },
  ur: {
    defaultMessage: 'Urdu',
    description: 'Name of a language a provider speaks: Urdu',
  },
  vi: {
    defaultMessage: 'Vietnamese',
    description: 'Name of a language a provider speaks: Vietnamese',
  },
  yue: {
    defaultMessage: 'Cantonese',
    description: 'Name of a language a provider speaks: Cantonese',
  },
  'zh-TW': {
    defaultMessage: 'Taiwanese Mandarin',
    description: 'Name of a language a provider speaks: Taiwanese Mandarin',
  },
})

export const IS_PRODUCTION = process.env.NODE_ENV === 'production'
export const IS_PRODUCTION_MOBILE = process.env.PROFILE === 'production'

export const VENDOR_STAGING_URL = 'https://vendorapi.staging.lyrahealth.com'
export const VENDOR_PROD_URL = 'https://vendorapi.lyrahealth.com'

// For web: process.env.PROFILE will always be undefined
// For mobile: process.env.NODE_ENV is always 'production' and we use process.env.PROFILE to define the build
export const VENDOR_BASE_API_URL =
  (IS_PRODUCTION && !process.env.PROFILE) || IS_PRODUCTION_MOBILE ? VENDOR_PROD_URL : VENDOR_STAGING_URL

export const BCTFellowshipInfoVideoUrl = 'https://vimeo.com/748166679'
export const BCTFellowshipInfoVideoId = '748166679'
export const LYRA_ALL_CUSTOMER_HIGH_ALERT_TEAM_PHONE = '(877) 505-7147'
export const CARE_ADVISOR_DISPLAY_PHONE_NUMBER = '(877) 505-7147'
export const CARE_ADVISOR_TRUNK_PHONE_NUMBER = '1-877-505-7147'
export const RAW_TRUNK_PHONE_NUMBER = '18775057147'
export const CARE_ADVISOR_EMAIL = 'care@lyrahealth.com'
export const ICAS_POLICIES_LINK = 'https://icasworld.com/icas-policies/'
export const ICAS_CONTACT_EMAIL = 'Internationaleap@icasworld.com'
export const ICAS_LIVE_CHAT_LINK =
  'https://salesiq.zoho.eu/signaturesupport.ls?widgetcode=a8f1afc834944fe50b5aa15c7794568539b5e75df986f253a1b11fab47040512ada48887b90b8d00f40a15f507be9152'
export const ICAS_DEFAULT_MAIN_PHONE_NUMBER_DISPLAY = '+44 33 00 241 021'
export const DEFAULT_COUNTRY_ISO_CODE = 'US'
export const DOMESTIC_COUNTRY_ISO_CODES = ['US', 'UM', 'VI'] // Countries to be filtered out for OUS-only customers

export const DEFAULT_LAUNCH_DATE = '2023-01-01T00:00:00+00:00'
export const STAGING_DEFAULT_DIRECT_CARE_NAVIGATOR_BOOKING_LINK_BASE = 'https://go.oncehub.com/DirectBookingCCNsTEST' // This link is used for customers launching on or after 1/1/2023
export const STAGING_DEFAULT_DIRECT_CARE_ADVOCATE_BOOKING_LINK_BASE = 'https://go.oncehub.com/DirectBookingCAsTEST' // This link is used for customers that launched before 1/1/2023
export const STAGING_DIRECT_NAVGIATOR_LINK =
  'https://go.oncehub.com/DedicatedCareNavigatorTest?name=ebay&email=carestaging@lyrahealth.com'
export const PRODUCTION_DEFAULT_DIRECT_CARE_NAVIGATOR_BOOKING_LINK_BASE = 'https://go.oncehub.com/DirectBookingCNs'
export const PRODUCTION_ASD_BOOKING_LINK_BASE = 'https://go.oncehub.com/LyraASDCheckin'

export const SCHEDULE_ONCE_ASSERTIVE_TRIAGE_LINK = 'https://go.oncehub.com/assertivetriage'

export const INTL_PRIVACY_POLICY_LINK = 'https://lyrahealthinternational.com/privacy-policy/'

export const SUICIDE_PREVENTION_LIFELINE = '988'
export const LYRIANS_SUPPORT_LINK = 'https://lyrians.zendesk.com/'
export const STAGING_REFER_ORGANIZATION_LINK = 'https://lyrastage.wpengine.com/refer'
export const PRODUCTION_REFER_ORGANIZATION_LINK = 'https://www.lyrahealth.com/refer'

export const DOMESTIC_PROVIDER_CONTACT_EMAIL = 'providers@lyrahealth.com'
export const INTERNATIONAL_PROVIDER_CONTACT_EMAIL = 'internationalproviders@lyrahealth.com'

export const CDN_ASSETS_LINK_BASE = 'https://assets.lyrahealth.com'

export const APPLE_APP_STORE_LINK = 'https://apps.apple.com/app/lyra-health/id1534654470'
export const GOOGLE_PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.lyra.lyrawebmobile&pli=1'

export const LYRA_MOBILE_APP_LINK = 'https://www.lyrahealth.com/app/'
export const BRANCH_APP_LINK = IS_PRODUCTION ? 'https://lyra.app.link/' : 'https://lyra.test-app.link/'

export const USER_MIN_AGE_REQUIREMENT_DEPRECATED = {
  DEFAULT: 0,
  DOMESTIC_EMPLOYEE: 15,
  DOMESTIC_DEPENDENT: 18,
  INTERNATIONAL_EMPLOYEE: 16,
  INTERNATIONAL_DEPENDENT: 18,
}

export const USER_MIN_AGE_REQUIREMENT = {
  DEFAULT: 0,
  DOMESTIC_EMPLOYEE: 13,
  DOMESTIC_DEPENDENT: 13,
  INTERNATIONAL_EMPLOYEE: 16,
  INTERNATIONAL_DEPENDENT: 18,
}

const THIS_YEAR = new Date().getFullYear()

export const USER_MAX_AGE_REQUIREMENT = {
  // IRA limitation that minimum dob is 1900
  ADULT: Math.min(150, THIS_YEAR - 1900),
}

export const DEFAULT_AGE_CONSTRAINTS: { min: number; max: number } = {
  min: USER_MIN_AGE_REQUIREMENT.DEFAULT,
  max: USER_MAX_AGE_REQUIREMENT.ADULT,
}

export type EligibilityType = 'dependent_other' | 'employee' | 'unknown'

export enum ELIGIBILITY_TYPES {
  DEPENDENT = 'dependent_other',
  EMPLOYEE = 'employee',
  UNKNOWN = 'unknown',
}

export type GenerateCustomMetadata = (params: {
  intl: IntlShape
  isMobileSized: boolean
  isInternational: boolean
  eligibilityInfo: EligibilityInfo
  eligibilityType?: EligibilityType
  colors: ThemeColors
}) => FormMetadata

export interface CustomEligibilityMetadata {
  generateMetadata: GenerateCustomMetadata
  getCustomFields?: (eligibilityInfo: EligibilityInfo) => {
    [name: string]: FunctionComponent
  }
}

export type EligibilityInfo = {
  customerName: string
  eligibilityQuestionTitle?: MessageDescriptor | string
  eligibilityQuestionSubtitle?: string
  eligibilityQuestionSubtitleComponent?: string
  /** Title shown above form asking a dependent for the information of their benefit provider*/
  eligibilityDependentTitle?: MessageDescriptor | string
  dependentButtonText?: MessageDescriptor | string
  employeeRegistrationNomenclature?: string
  dependentRegistrationNomenclature?: string
  hideEmployeeInfo?: boolean
  hideDependentField?: boolean
  eligibilityTypeSelectionCopy?: React.ReactNode
  dependentTypes?: DependentTypes
  dependentTypeId?: string
  showRegistrationTips?: boolean
  showEligibilityQuestionForInternationalRegistration?: boolean
  customEligibilityMetadata?: CustomEligibilityMetadata
  hasBlockingEligibilityCheck?: boolean
  checkAdultDependentEligibilityUsingDependentInfo?: boolean
  employeeEligibilityError?: React.ReactNode
  isPristineConfirmEligibility?: boolean // true for confirm eligibility form is in pristine state
  isAttestation?: boolean // true for eligibility form during verifying attestation
  onContactCNT?: () => void
}

// Used to identify different types of customers and distinguish between our standard employer customers and all other non-standard customers
export enum PROGRAM_MEMBERSHIP_TYPES {
  EMPLOYEE = 'employee', // Standard customer
  MEMBER = 'member', // Customers whose clients are referred to as members rather than employees
}

export enum FetchStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const LYRA_LEARN_SSO_LINK =
  process.env.NODE_ENV === 'production'
    ? 'https://lyrahealth.okta.com/home/lyrahealth_starbuckslyralearn_1/0oafej0g41V558jek297/alnfej59laieeHAEs297'
    : 'https://lyrahealth.oktapreview.com/home/lyrahealth_testdocebointegration_1/0oa1ap9oujqvQ6ODb0h8/aln1ap9vap8Yrp71Z0h8'

/**
 * Meeting format required by the BE
 */
export const SESSION_FORMAT = [
  {
    id: 'Video',
    text: 'Video',
    group: 0,
  },
  {
    id: 'In-Person',
    text: 'In-Person',
    group: 0,
  },
  {
    id: 'Live-Messaging',
    text: 'Live-Messaging',
    group: 0,
  },
]
/**
 * Default services that Lyra does not cover.
 * You should only be importing this if you need this list in a custom string, e.g. a custom "servicesNotCovered" string
 * that doesn't match the content of the servicesNotCovered string here. Otherwise, you probably want to be importing
 * servicesNotCovered instead.
 * If your customer has a custom "servicesNotCovered" string and doesn't cover meds, check defaultWithoutMeds.js
 */
export const excludedServices = `inpatient or residential treatment, hospitalization (including partial),
  intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication,
  autism spectrum disorder treatment, services for remedial education, executive coaching, and non-evidence-based behavioral health care`

export enum CUSTOMERS_SUBECTIONS {
  UNSUPPORTED_OPTIONS = 'unsupported_options',
}

export const CONTACT_CARE_TEAM_REQUEST_TYPES = {
  USER_INITIATED_DEFAULT: 'USER_INITIATED_DEFAULT',
  ZERO_PROVIDERS_FOUND: 'ZERO_PROVIDERS_FOUND',
  ZERO_POC_PROVIDERS_FOUND: 'ZERO_POC_PROVIDERS_FOUND',
  ASSERTIVE_TRIAGE_SCREENING: 'Assertive Triage Screening',
  CARE_PREFERENCES: 'CARE_PREFERENCES',
  HOMEBASE: 'HOMEBASE',
  CSS_TEAM_SUPPORT: 'CSS_TEAM_SUPPORT',
  SPECIALIZED_NEED: 'specialized need',
  OTHER_PRIMARY_NEEDS: 'other primary needs',
} as const

export const COMPONENT_CONFIGURATION = {
  [CONTACT_CARE_TEAM_REQUEST_TYPES.USER_INITIATED_DEFAULT]: {
    requestHeader: null,
  },
  [CONTACT_CARE_TEAM_REQUEST_TYPES.ZERO_PROVIDERS_FOUND]: {
    requestHeader: 'Zero providers found',
  },
  [CONTACT_CARE_TEAM_REQUEST_TYPES.ZERO_POC_PROVIDERS_FOUND]: {
    requestHeader: 'Zero POC providers found',
  },
  [CONTACT_CARE_TEAM_REQUEST_TYPES.CARE_PREFERENCES]: {
    requestHeader: 'Contact care team from care preferences',
  },
}

export const navigatorTicketTypes = {
  [CONTACT_CARE_TEAM_REQUEST_TYPES.USER_INITIATED_DEFAULT]: 'GET_OPTIONS',
  [CONTACT_CARE_TEAM_REQUEST_TYPES.ZERO_PROVIDERS_FOUND]: 'ZERO_MATCH',
  [CONTACT_CARE_TEAM_REQUEST_TYPES.ZERO_POC_PROVIDERS_FOUND]: 'ZERO_MATCH_POC',
  [CONTACT_CARE_TEAM_REQUEST_TYPES.CARE_PREFERENCES]: 'CARE_PREFERENCES',
  [CONTACT_CARE_TEAM_REQUEST_TYPES.ASSERTIVE_TRIAGE_SCREENING]: 'Assertive Triage Screening',
}

export type ContactCareTeamRequestType =
  (typeof CONTACT_CARE_TEAM_REQUEST_TYPES)[keyof typeof CONTACT_CARE_TEAM_REQUEST_TYPES]

export const FOOTER_VERSION = {
  US_VERSION: 'US_VERSION',
  US_VERSION_OUS_SUPPORT: 'US_VERSION_OUS_SUPPORT',
  OUS_VERSION: 'OUS_VERSION',
}

export const INACTIVITY_WARNING_DURATION_MS = 60 * 1000

export const RESEND_EMAIL_TIMEOUT_DURATION_SECONDS = 60

export const SESSION_COUNT_STARTS_ON_FORMAT = 'MMM-dd'

export const STATES = [
  {
    value: 'AL',
    label: 'Alabama',
  },
  {
    value: 'AK',
    label: 'Alaska',
  },
  {
    value: 'AS',
    label: 'American Samoa',
  },
  {
    value: 'AZ',
    label: 'Arizona',
  },
  {
    value: 'AR',
    label: 'Arkansas',
  },
  {
    value: 'CA',
    label: 'California',
  },
  {
    value: 'CO',
    label: 'Colorado',
  },
  {
    value: 'CT',
    label: 'Connecticut',
  },
  {
    value: 'DE',
    label: 'Delaware',
  },
  {
    value: 'DC',
    label: 'District of Columbia',
  },
  {
    value: 'FL',
    label: 'Florida',
  },
  {
    value: 'GA',
    label: 'Georgia',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'HI',
    label: 'Hawaii',
  },
  {
    value: 'ID',
    label: 'Idaho',
  },
  {
    value: 'IL',
    label: 'Illinois',
  },
  {
    value: 'IN',
    label: 'Indiana',
  },
  {
    value: 'IA',
    label: 'Iowa',
  },
  {
    value: 'KS',
    label: 'Kansas',
  },
  {
    value: 'KY',
    label: 'Kentucky',
  },
  {
    value: 'LA',
    label: 'Louisiana',
  },
  {
    value: 'ME',
    label: 'Maine',
  },
  {
    value: 'MD',
    label: 'Maryland',
  },
  {
    value: 'MA',
    label: 'Massachusetts',
  },
  {
    value: 'MI',
    label: 'Michigan',
  },
  {
    value: 'MN',
    label: 'Minnesota',
  },
  {
    value: 'MS',
    label: 'Mississippi',
  },
  {
    value: 'MO',
    label: 'Missouri',
  },
  {
    value: 'MT',
    label: 'Montana',
  },
  {
    value: 'NE',
    label: 'Nebraska',
  },
  {
    value: 'NV',
    label: 'Nevada',
  },
  {
    value: 'NH',
    label: 'New Hampshire',
  },
  {
    value: 'NJ',
    label: 'New Jersey',
  },
  {
    value: 'NM',
    label: 'New Mexico',
  },
  {
    value: 'NY',
    label: 'New York',
  },
  {
    value: 'NC',
    label: 'North Carolina',
  },
  {
    value: 'ND',
    label: 'North Dakota',
  },
  {
    value: 'MP',
    label: 'Northern Mariana Islands',
  },
  {
    value: 'OH',
    label: 'Ohio',
  },
  {
    value: 'OK',
    label: 'Oklahoma',
  },
  {
    value: 'OR',
    label: 'Oregon',
  },
  {
    value: 'PA',
    label: 'Pennsylvania',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
  },
  {
    value: 'RI',
    label: 'Rhode Island',
  },
  {
    value: 'SC',
    label: 'South Carolina',
  },
  {
    value: 'SD',
    label: 'South Dakota',
  },
  {
    value: 'TN',
    label: 'Tennessee',
  },
  {
    value: 'TX',
    label: 'Texas',
  },
  {
    value: 'UT',
    label: 'Utah',
  },
  {
    value: 'VT',
    label: 'Vermont',
  },
  {
    value: 'VI',
    label: 'Virgin Islands',
  },
  {
    value: 'VA',
    label: 'Virginia',
  },
  {
    value: 'WA',
    label: 'Washington',
  },
  {
    value: 'WV',
    label: 'West Virginia',
  },
  {
    value: 'WI',
    label: 'Wisconsin',
  },
  {
    value: 'WY',
    label: 'Wyoming',
  },
]

// LaunchDarkly Experimentation custom metric events.
export enum LD_CUSTOM_EVENTS {
  LOCATION_FINDER_VIEWED = 'location-finder-viewed',
  MEMBER_PREFERENCES_SCREEN_VIEWED = 'member-preferences-screen-viewed',
  MEET_MY_MATCHES_CLICKED = 'meet-my-matches-clicked',
  PROVIDERS_RECOMMENDATIONS_SCREEN_VIEWED = 'providers-recommendations-screen-viewed',
  APPOINTMENT_BOOKING_SCREEN_VIEWED = 'appointment-booking-screen-viewed',
  BOOK_APPOINTMENT = 'book-appointment',
  MICROSITE_VIEWED = 'microsite-viewed',
  MICROSITE_SELF_CARE_TOPICS_VIEWED = 'microsite-self-care-topics-viewed',
  MICROSITE_SELF_CARE_REGISTER = 'microsite-self-care-register',
  REGISTRATION_CAPTURE_EMAIL_VIEWED = 'registration-capture-email-viewed',
  REGISTRATION_CAPTURE_EMAIL_SIGN_UP_CLICKED = 'registration-capture-email-sign-up-clicked',
  REGISTRATION_SET_UP_YOUR_ACCOUNT_VIEWED = 'registration-set-up-your-account-viewed',
  REGISTRATION_USER_COMPLETES_REGISTRATION = 'registration-user-completes-registration',
  FINISHED_TRIAGE = 'finished-triage',
  INDIVIDUAL_THERAPY_REC_CLICKED = 'individual-therapy-rec-clicked',
  INITIAL_VIDEO_SESSION_END = 'initial-video-session-end',
  HOMEBASE_VIEWED = 'homebase-viewed',
  EMAIL_CAPTURE_PAGE_VIEWED = 'email-capture-page-view',
  EMAIL_CAPTURE_PAGE_CONTINUE = 'email-capture-page-click-continue',
  EMAIL_CAPTURE_PAGE_CONTINUE_WITH_GOOGLE = 'email-capture-page-click-continue-with-google',
  EMAIL_CAPTURE_PAGE_CONTINUE_WITH_APPLE = 'email-capture-page-click-continue-with-apple',
}

export const VIDEO_ASPECT_RATIO = 16 / 9

export enum PATHWAYS_TYPE {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  COMING_SOON = 'comingSoon',
}

export const NATIVE_APP_FROM_LW_STORAGE_KEY = 'nativeAppFromLW'

export enum CoverageType {
  EAP_ONLY = 'eapOnly',
  EAP_ONLY_DEPENDENT_DISABLED = 'eapOnlyDependentDisabled',
  HPI_ONLY = 'hpiOnly',
  HPI_ONLY_CHILDREN_DISABLED = 'hpiOnlyChildrenDisabled',
  EAP_AND_HPI = 'eapAndHpi',
}

export const COST_MESSAGING_COVERAGE_KEY_DEPENDENT_SUFFIX = 'WithDependent'
export const COST_MESSAGING_COVERAGE_KEY_HPI_MEDS_SUFFIX = 'WithHpiMeds'

const eapWithHpiMeds = CoverageType.EAP_AND_HPI + COST_MESSAGING_COVERAGE_KEY_HPI_MEDS_SUFFIX
const eapWithEapMeds = CoverageType.EAP_ONLY + 'WithEapMeds'
const hpiWithHpiMeds = CoverageType.HPI_ONLY + COST_MESSAGING_COVERAGE_KEY_HPI_MEDS_SUFFIX
const EAP_COACHING_WITH_HPI_THERAPY_VALUE = 'eapCoachingWithHpiTherapy'

export enum CostMessagingCoverageSubCategoryType {
  EAP_ONLY_WITH_DEPENDENT = CoverageType.EAP_ONLY + COST_MESSAGING_COVERAGE_KEY_DEPENDENT_SUFFIX,
  EAP_ONLY_WITH_EAP_MEDS = eapWithEapMeds,
  EAP_ONLY_WITH_EAP_MEDS_WITH_DEPENDENT = eapWithEapMeds + COST_MESSAGING_COVERAGE_KEY_DEPENDENT_SUFFIX,
  EAP_AND_HPI_WITH_DEPENDENT = CoverageType.EAP_AND_HPI + COST_MESSAGING_COVERAGE_KEY_DEPENDENT_SUFFIX,
  EAP_WITH_HPI_MEDS = eapWithHpiMeds,
  EAP_WITH_HPI_MEDS_WITH_DEPENDENT = eapWithHpiMeds + COST_MESSAGING_COVERAGE_KEY_DEPENDENT_SUFFIX,
  EAP_COACHING_WITH_HPI_THERAPY = EAP_COACHING_WITH_HPI_THERAPY_VALUE,
  EAP_COACHING_WITH_HPI_THERAPY_WITH_DEPENDENT = EAP_COACHING_WITH_HPI_THERAPY_VALUE +
    COST_MESSAGING_COVERAGE_KEY_DEPENDENT_SUFFIX,
  HPI_ONLY_WITH_DEPENDENT = CoverageType.HPI_ONLY + COST_MESSAGING_COVERAGE_KEY_DEPENDENT_SUFFIX,
  HPI_ONLY_WITH_HPI_MEDS = hpiWithHpiMeds,
  HPI_ONLY_WITH_HPI_MEDS_WITH_DEPENDENT = hpiWithHpiMeds + COST_MESSAGING_COVERAGE_KEY_DEPENDENT_SUFFIX,
}

export type CostMessagingCoverageType = CoverageType | CostMessagingCoverageSubCategoryType

export type CostMessagingCoverageModalData = {
  coverageModalContents: CostMessagingModalContent[]
  costMessagingCoverageType?: CostMessagingCoverageType
  isCostMessagingEnabled: boolean
}

export type CostMessagingModalContent = {
  title: React.ReactNode
  description: React.ReactNode
}

export const WORK_HUB_EVENT_REGISTRATION_RETRY_MESSAGE = defineMessage({
  defaultMessage: "We couldn't register you for this event right now. Refresh the page and try again.",
  description: 'Message to user indicating they could not be registered for a Zoom event at this time',
})
export const WORK_HUB_EVENT_REGISTRATION_LIMIT_REACHED_MESSAGE = defineMessage({
  defaultMessage: "You've hit today's limit of three registrations for this event. Try again tomorrow!",
  description: 'Message to user indicating they reached the limit for registering for this specific event',
})
export const WORK_HUB_EVENT_DEREGISTRATION_RETRY_MESSAGE = defineMessage({
  defaultMessage: "We couldn't unregister you for this event right now. Refresh the page and try again.",
  description: 'Message to user indicating they could not be deregistered for a Zoom event at this time',
})

export enum DEPENDENT_VARIANT {
  CHILD = 'child',
  TEEN = 'teen',
  SELF_MANAGED_TEEN = 'self-managed-teen',
  ADULT = 'adult',
}
