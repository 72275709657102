import React, { FunctionComponent } from 'react'
import Svg, { ClipPath, Defs, G, Mask, Path, Rect } from 'react-native-svg'

type ValuePropConfidentialCareNavigatorProps = {
  size?: number
}

export const ValuePropConfidentialCareNavigator: FunctionComponent<ValuePropConfidentialCareNavigatorProps> = ({
  size = 56,
}) => {
  return (
    <Svg width={size} height={size} viewBox='0 0 56 56' fill='none'>
      <G clipPath='url(#clip0_10302_10803)'>
        <Path
          d='M28.1968 0.21875C24.3314 0.21875 20.5811 0.881616 17.0498 2.18853C8.64285 5.30478 2.73238 12.2134 0.833422 21.1427C-0.421486 26.9647 -0.261026 32.3495 1.32697 37.5971C3.3543 44.2988 8.33078 49.9503 14.9805 53.1019C18.2815 54.67 22.0739 55.5653 26.2503 55.7623C26.787 55.7866 27.3326 55.7999 27.8748 55.7999C31.2257 55.7999 34.5964 55.3406 37.8942 54.4354C38.1476 54.3723 38.3888 54.3071 38.6433 54.2318C41.6069 53.3642 44.2738 51.992 46.5557 50.1583C49.0334 48.1786 51.0729 45.6688 52.6111 42.703C52.9143 42.1187 53.2043 41.5035 53.5241 40.7676C55.2836 36.6819 56.1335 32.1082 55.983 27.5357C55.9122 25.5139 55.6112 23.3648 55.091 21.1427C54.2345 17.5374 52.838 14.1467 51.0519 11.3314C50.5628 10.5756 50.1135 9.94041 49.6686 9.3694C46.4583 5.22842 41.4564 2.32244 35.2084 0.970145C32.9133 0.472166 30.5539 0.219857 28.1979 0.219857L28.1968 0.21875Z'
          fill='white'
        />
        <Mask
          id='mask0_10302_10803'
          // @ts-ignore maskType not supported in react-native-svg
          style={{
            maskType: 'luminance',
          }}
          maskUnits='userSpaceOnUse'
          x={2}
          y={2}
          width={52}
          height={52}
        >
          <Path
            d='M53.58 27.6108C53.5114 25.6731 53.2148 23.6657 52.7478 21.6705C51.9843 18.4557 50.7294 15.2864 49.0086 12.5729C48.6091 11.9577 48.1996 11.3645 47.7548 10.7946C44.5279 6.63371 39.6377 4.31866 34.6325 3.23527C29.0573 2.02684 23.1291 2.37986 17.7698 4.36403C10.0168 7.23681 4.73819 13.5302 3.01629 21.6251C2.35453 24.6915 2.16087 28.1575 2.45745 31.4873H2.43421C2.60573 33.3796 2.94768 35.2266 3.46005 36.9252C5.36454 43.2186 9.99243 48.2128 15.8874 51.0059C19.08 52.5219 22.6477 53.2745 26.2741 53.446C29.9791 53.6175 33.7416 53.1494 37.2197 52.1922C37.4477 52.1357 37.6646 52.0782 37.8926 52.0096C40.6171 51.2117 42.9885 49.98 45.0181 48.35C47.2302 46.5827 49.0539 44.3595 50.4682 41.635C50.7758 41.0419 51.0492 40.4498 51.3114 39.8445C52.9758 35.9791 53.7172 31.7839 53.5689 27.623L53.58 27.6119V27.6108Z'
            fill='white'
          />
        </Mask>
        <G mask='url(#mask0_10302_10803)'>
          <Path
            d='M29.0069 57.3312C45.0691 57.3312 58.09 44.3103 58.09 28.2481C58.09 12.186 45.0691 -0.834961 29.0069 -0.834961C12.9448 -0.834961 -0.0761719 12.186 -0.0761719 28.2481C-0.0761719 44.3103 12.9448 57.3312 29.0069 57.3312Z'
            fill='white'
            stroke='#3BA5B5'
            strokeMiterlimit={10}
          />
        </G>
        <Path
          d='M28.1772 23.2237C28.0059 23.3773 27.742 23.3643 27.5873 23.1943C26.6553 22.1738 23.5443 19.2589 21.1136 22.745C18.3294 26.7385 25.6258 34.0474 27.6509 35.2559C27.7767 35.3312 27.9321 35.3362 28.0623 35.2688C29.0578 34.7535 33.5179 32.2261 35.5763 27.355C37.8978 21.8609 33.1073 20.5857 31.4567 21.0809C30.2638 21.439 28.8062 22.6589 28.1772 23.223V23.2237Z'
          fill='#F47352'
        />
        <Path
          d='M40.8348 35.3562L38.9673 34.6891C39.0073 34.5679 42.9778 22.4246 38.4408 15.7873C36.4976 12.9441 33.1913 11.4846 28.6144 11.4473C23.9892 11.4115 20.5382 12.8586 18.3551 15.7542C13.3371 22.4067 16.3278 34.6437 16.3581 34.7663L14.4466 35.2804C14.4121 35.1453 13.6155 31.9341 13.4832 27.7885C13.4047 25.3367 13.5783 23.0655 13.9987 21.0382C14.5334 18.4541 15.4747 16.2545 16.7936 14.5042C19.3433 11.1207 23.2601 9.40625 28.4379 9.40625C28.5013 9.40625 28.5647 9.40625 28.6295 9.40625C33.8818 9.44897 37.7256 11.2007 40.0575 14.6131C41.2648 16.3799 42.0503 18.5891 42.3921 21.1787C42.6595 23.2033 42.6595 25.469 42.3894 27.9112C41.9346 32.0361 40.8802 35.2239 40.8348 35.3576V35.3562Z'
          fill='#3BA5B5'
        />
        <Path
          d='M17.333 27.375C16.9912 25.8204 16.4068 24.1418 15.3291 23.0089C14.2348 21.8609 12.654 21.5149 11.3131 22.3873C9.91144 23.2997 9.30228 24.9949 9.20718 26.7052C9.11347 28.4004 9.36016 30.2471 9.82461 31.8651C10.2725 33.4266 11.0429 35.1411 12.3991 35.9804C13.0758 36.3994 13.7897 36.4766 14.4305 36.2781C14.4374 36.2781 14.4443 36.2781 14.4526 36.2781C16.1905 36.2561 17.2296 34.4878 17.5025 32.8051C17.7933 31.0079 17.723 29.1473 17.333 27.375Z'
          fill='#068298'
        />
        <Path
          d='M46.2831 23.9462C45.5968 22.4977 44.2034 21.6529 42.6653 22.0402C41.0556 22.4467 39.96 23.8539 39.3453 25.4457C38.7361 27.0223 38.4067 28.8553 38.3516 30.5422C38.2979 32.1699 38.5046 34.0483 39.5327 35.2887C39.7202 35.5134 39.9255 35.6967 40.1433 35.8414C39.7146 37.8301 38.8588 39.7127 37.3235 41.0564C36.3684 41.893 35.2025 42.2996 33.9828 42.5077C33.3116 42.6221 32.6308 42.6979 31.9499 42.7599C31.8755 42.6579 31.7859 42.56 31.6798 42.4677C31.5144 42.323 31.3408 42.1907 31.1616 42.068C31.0899 41.9619 30.9962 41.8723 30.8777 41.8144C29.6015 41.1887 28.0028 40.7753 26.6163 41.2577C25.2354 41.7386 24.3699 43.1265 25.0094 44.5929C25.5868 45.9201 27.0877 46.5747 28.4328 46.5154C29.749 46.4576 31.021 45.8277 31.83 44.7445C31.8562 44.7086 31.8824 44.6714 31.9058 44.6356C32.4075 44.5874 32.9091 44.5364 33.4094 44.4771C34.6815 44.3241 35.926 44.0788 37.0782 43.4724C39.1165 42.4002 40.5319 40.4046 41.3492 38.2422C41.5821 37.6275 41.7599 36.9963 41.8977 36.3554C43.3517 36.5139 44.6513 35.3011 45.3459 33.9491C46.1728 32.3408 46.6759 30.5519 46.8482 28.7437C47.0011 27.1574 46.9598 25.3726 46.2845 23.9462H46.2831Z'
          fill='#3BA5B5'
        />
        <Path
          d='M46.8481 28.7451C46.6745 30.5533 46.1714 32.3422 45.3445 33.9506C44.694 35.2171 43.5101 36.3652 42.165 36.3693C41.7984 36.299 41.4043 36.2218 40.9949 36.1405C40.6104 36.0633 40.2535 35.9007 39.9296 35.6857C39.9268 35.6843 39.9241 35.683 39.9227 35.6816C39.7863 35.5686 39.6553 35.439 39.5327 35.2902C38.5032 34.0512 38.2978 32.1713 38.3516 30.5437C38.4067 28.8568 38.7375 27.0252 39.3452 25.4471C39.9599 23.8553 41.057 22.4482 42.6653 22.0416C44.2034 21.653 45.5981 22.4992 46.2844 23.9477C46.9597 25.3741 46.9997 27.1575 46.8481 28.7451Z'
          fill='#068298'
        />
        <Path
          d='M31.9643 44.6302C31.945 44.6302 31.9257 44.6343 31.9065 44.6357C31.8816 44.6715 31.8568 44.7087 31.8293 44.7446C31.0203 45.8278 29.7482 46.4577 28.432 46.5155C27.0883 46.5762 25.5875 45.9202 25.0086 44.593C24.3705 43.1266 25.236 41.7387 26.617 41.2578C28.0034 40.774 29.6035 41.1888 30.8783 41.8145C30.9968 41.8724 31.0906 41.9606 31.1622 42.0681C31.3414 42.1908 31.515 42.3231 31.6804 42.4678C31.7424 42.5216 31.799 42.5781 31.8499 42.6346C31.8499 42.6346 31.8499 42.6355 31.8499 42.6373C32.0098 42.935 32.1352 43.2961 32.1738 43.7206C32.2014 44.0279 32.1159 44.3353 31.963 44.6302H31.9643Z'
          fill='#068298'
        />
      </G>
      <Defs>
        <ClipPath id='clip0_10302_10803'>
          <Rect width={56} height={56} fill='white' />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
