import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled from 'styled-components/native'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'
import { ProviderBookableCalendarSettings, Subhead, SubheadSize } from '@lyrahealth-inc/ui-core-crossplatform'

import './providerBookableCalendar.scss'

const Container = styled.View({
  width: '100%',
})

const Header = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  padding: theme.spacing['24px'],
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.borderDefault,
}))

const ContentContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  marginTop: theme.spacing['32px'],
  marginBottom: theme.spacing['32px'],
}))

const CalendarContainer = styled.View({
  flexDirection: 'column',
  maxWidth: '944px',
  width: '100%',
})

export const Settings: FunctionComponent = () => {
  const { isInProductCalendarEnabled } = useFlags()
  if (!isInProductCalendarEnabled) {
    return null
  }

  return (
    <Container>
      <Header>
        <Subhead
          size={SubheadSize.LARGE}
          text={<FormattedMessage defaultMessage='Settings' description='Header for settings' />}
        />
      </Header>
      <ContentContainer>
        <CalendarContainer>
          <div className='lc-bookable-calendar'>
            <ProviderBookableCalendarSettings />
          </div>
        </CalendarContainer>
      </ContentContainer>
    </Container>
  )
}
