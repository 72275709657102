import { addMinutes, differenceInMinutes, parseISO, subMinutes } from 'date-fns'

export enum OffsetDirection {
  BEFORE_AND_AFTER = 'BEFORE_AND_AFTER',
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  NONE = 'NONE',
}

export const getAdjustedEvent: (
  event: { startStr: string; endStr: string },
  startingRange: { startStr: string; endStr: string } | null,
) => {
  start: string
  end: string
  offset: OffsetDirection
} = (event, startingRange) => {
  //ISO dates from this library do not include tz info so considering as UTC while processing
  const startTime = parseISO(event.startStr + 'Z')
  const endTime = parseISO(event.endStr + 'Z')
  const differenceHours = differenceInMinutes(endTime, startTime) / 60
  if (!startingRange) {
    return {
      start: startTime.toISOString(),
      end: endTime.toISOString(),
      offset: OffsetDirection.NONE,
    }
  }

  if (startingRange.startStr === event.startStr) {
    return {
      start: startTime.toISOString(),
      end: differenceHours % 1 !== 0 ? addMinutes(endTime, 30).toISOString() : endTime.toISOString(),
      offset: differenceHours % 1 !== 0 ? OffsetDirection.AFTER : OffsetDirection.NONE,
    }
  }
  return {
    start: differenceHours % 1 !== 0 ? startTime.toISOString() : subMinutes(startTime, 30).toISOString(),
    end: addMinutes(endTime, 30).toISOString(),
    offset: differenceHours % 1 !== 0 ? OffsetDirection.BEFORE : OffsetDirection.BEFORE_AND_AFTER,
  }
}
