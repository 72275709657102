import React, { FunctionComponent, MutableRefObject, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Popover } from 'react-native-popper'

import { EventContentArg, formatDate, formatRange } from '@fullcalendar/core'
import { addMinutes, differenceInMinutes, parseISO, subMilliseconds } from 'date-fns'
import { getTimezoneOffset } from 'date-fns-tz'
import styled, { useTheme } from 'styled-components/native'

import {
  CalendarExtendedProps,
  getAdjustedEvent,
  getProgramNameFromId,
  getProgramNameLabel,
  OffsetDirection,
} from '@lyrahealth-inc/shared-app-logic'

import { BodyText, EventIcon, PressableOpacity, Subhead, XIcon } from '../../atoms'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'
import { ProviderCalendarPopover } from '../providerCalendarPopover/ProviderCalendarPopover'

export type ProviderCalendarEventProps = {
  args: EventContentArg
  startingRange: MutableRefObject<{ startStr: string; endStr: string } | null> | null
  timeZone?: string
  disableEditing?: boolean
}

type EventStyles = {
  container: {
    backgroundColor: string
    borderColor: string
  }
  textColor?: string
}

const EventContainer = styled(PressableOpacity)<{ offset?: OffsetDirection }>(
  ({ theme, offset = OffsetDirection.NONE }) => ({
    flexDirection: 'column',
    height:
      offset !== OffsetDirection.NONE
        ? `calc(100% + ${offset === OffsetDirection.BEFORE_AND_AFTER ? '82px' : '41px'})`
        : '100%',
    marginTop: offset === OffsetDirection.BEFORE_AND_AFTER ? '-41px' : '0px',
    borderWidth: '1px',
    padding: theme.spacing['4px'],
    borderRadius: '4px',
    gap: theme.spacing['4px'],
    width: '100%',
  }),
)

const EventRow = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const SelectedAmountBadge = styled(Subhead)(({ theme }) => ({
  position: 'absolute',
  flexDirection: 'row',
  bottom: -11,
  right: 11,
  backgroundColor: theme.colors.backgroundHover,
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  height: '22px',
  width: '22px',
  borderRadius: '11px',
  color: theme.colors.textInverse,
}))

const TrashIconContainer = styled(PressableOpacity)(({ theme }) => ({
  position: 'absolute',
  top: -10,
  right: -10,
  backgroundColor: theme.colors.backgroundPrimary,
  height: '20px',
  width: '20px',
  borderRadius: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.colors.borderDefault}`,
}))

export const ProviderCalendarEvent: FunctionComponent<ProviderCalendarEventProps> = ({
  args,
  startingRange,
  timeZone = 'America/Los_Angeles',
  disableEditing = false,
}) => {
  const theme = useTheme() as ThemeType
  const pressableRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const eventProperties = args.event.extendedProps as CalendarExtendedProps
  const eventTitle = useMemo(() => {
    if (
      !eventProperties.clientFirstName ||
      !eventProperties.clientLastName ||
      eventProperties.appointmentSessionNumber == null ||
      !eventProperties.lyraProgramId ||
      !eventProperties.appointmentClass
    ) {
      return args.event.title
    }

    const programName = getProgramNameFromId(eventProperties.lyraProgramId)
    const programNameLabel = programName ? getProgramNameLabel(programName) : ''
    if (eventProperties.appointmentClass === 'initial') {
      return (
        <FormattedMessage
          defaultMessage='{firstName} {lastInitial}. • Intake • {programName}'
          description='Event title for lyra appointment'
          values={{
            firstName: eventProperties.clientFirstName,
            lastInitial: eventProperties.clientLastName.charAt(0),
            programName: programNameLabel,
          }}
        />
      )
    }
    return (
      <FormattedMessage
        defaultMessage='{firstName} {lastInitial}. • Session {sessionNumber} • {programName}'
        description='Event title for lyra appointment'
        values={{
          firstName: eventProperties.clientFirstName,
          lastInitial: eventProperties.clientLastName.charAt(0),
          sessionNumber: eventProperties.appointmentSessionNumber,
          programName: programNameLabel,
        }}
      />
    )
  }, [
    args.event.title,
    eventProperties.appointmentClass,
    eventProperties.appointmentSessionNumber,
    eventProperties.clientFirstName,
    eventProperties.clientLastName,
    eventProperties.lyraProgramId,
  ])

  const eventStyles = useMemo<EventStyles>(() => {
    const eventColors = theme.colors.components.providerCalendar.event
    if (args.isMirror && !args.isDragging) {
      return {
        container: {
          backgroundColor: eventColors.mirror.background,
          borderColor: eventColors.mirror.border,
        },
      }
    }

    if (!eventProperties.lyraEventType) {
      return {
        container: {
          backgroundColor: eventColors.default.background,
          borderColor: eventColors.default.border,
        },
      }
    }

    if (eventProperties.lyraEventType === 'bookable') {
      if (eventProperties.hovered === true) {
        return {
          container: {
            backgroundColor: eventColors.bookableHover.background,
            borderColor: eventColors.bookableHover.border,
          },
        }
      }
      return {
        container: {
          backgroundColor: eventColors.bookable.background,
          borderColor: eventColors.bookable.border,
        },
      }
    }

    if (eventProperties.appointmentClass === 'initial') {
      return {
        container: {
          backgroundColor: eventColors.initialSession.background,
          borderColor: eventColors.initialSession.border,
        },
        textColor: eventColors.initialSession.text,
      }
    }

    return {
      container: {
        backgroundColor: eventColors.recurringSession.background,
        borderColor: eventColors.recurringSession.border,
      },
      textColor: eventColors.recurringSession.text,
    }
  }, [
    args.isDragging,
    args.isMirror,
    eventProperties.appointmentClass,
    eventProperties.hovered,
    eventProperties.lyraEventType,
    theme.colors.components.providerCalendar.event,
  ])

  if (args.isMirror && !args.isDragging && startingRange) {
    const differenceHours = differenceInMinutes(parseISO(args.event.endStr), parseISO(args.event.startStr)) / 60
    if (differenceHours === 0.5) {
      startingRange.current = {
        startStr: args.event.startStr,
        endStr: addMinutes(parseISO(args.event.endStr), 30).toISOString(),
      }
    }
    const adjustedEvent = getAdjustedEvent(args.event, startingRange?.current ?? null)
    return (
      <EventContainer offset={adjustedEvent.offset} style={eventStyles.container}>
        <BodyText
          style={{ lineHeight: 14 }}
          color={theme.colors.textSecondary}
          size={BodyTextSize.CAPTION}
          text={formatRange(adjustedEvent.start.slice(0, -1), adjustedEvent.end.slice(0, -1), {
            hour: 'numeric',
            minute: 'numeric',
          })}
        />
        <SelectedAmountBadge
          color={theme.colors.textSecondary}
          size={SubheadSize.XSMALL}
          text={Math.ceil(differenceInMinutes(parseISO(adjustedEvent.end), parseISO(adjustedEvent.start)) / 60)}
        />
      </EventContainer>
    )
  }

  const isBookableSlot = eventProperties.lyraEventType === 'bookable'
  const isInitial = eventProperties.appointmentClass === 'initial'
  const startDate = subMilliseconds(parseISO(args.event.startStr), getTimezoneOffset(timeZone)).toISOString()
  const endDate = subMilliseconds(parseISO(args.event.endStr), getTimezoneOffset(timeZone)).toISOString()
  const isLyraSession = eventProperties.lyraEventType === 'session'
  return (
    <>
      <EventContainer
        testID={tID('ProviderCalendarEvent')}
        style={eventStyles.container}
        pressableRef={pressableRef}
        onPress={() => setIsOpen(!isOpen)}
      >
        <EventRow>
          <BodyText
            style={{ lineHeight: 18 }}
            numberOfLines={1}
            color={eventStyles.textColor ?? theme.colors.textPrimary}
            size={BodyTextSize.BADGE}
            text={eventTitle}
          />
          {isInitial && <EventIcon fillColor={eventStyles.textColor} />}
        </EventRow>
        <BodyText
          style={{ lineHeight: 14 }}
          color={eventStyles.textColor ?? theme.colors.textSecondary}
          size={BodyTextSize.CAPTION}
          text={
            isBookableSlot
              ? formatDate(args.event.startStr, { hour: 'numeric', minute: 'numeric' })
              : formatRange(args.event.startStr, args.event.endStr, {
                  hour: 'numeric',
                  minute: 'numeric',
                  timeZone,
                })
          }
        />
        {args.event.extendedProps.hovered && isBookableSlot && !disableEditing && (
          <TrashIconContainer onPress={() => args.event.remove()}>
            <XIcon size={16} />
          </TrashIconContainer>
        )}
      </EventContainer>
      {isLyraSession && (
        <Popover isOpen={isOpen} onOpenChange={setIsOpen} trigger={pressableRef} placement='right top'>
          <Popover.Content>
            <ProviderCalendarPopover
              startDate={startDate}
              endDate={endDate}
              extendedProps={eventProperties}
              timeZone='America/New_York'
              onClose={() => setIsOpen(false)}
            />
          </Popover.Content>
        </Popover>
      )}
    </>
  )
}
