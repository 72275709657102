import React, { FunctionComponent, useCallback, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { addMilliseconds, parseISO } from 'date-fns'
import { getTimezoneOffset } from 'date-fns-tz'
import { bindActionCreators } from 'redux'

import { MEETING_FORMATS, ProviderCalendarEvent, useFlags } from '@lyrahealth-inc/shared-app-logic'
import { ProviderCalendar, useFetcher } from '@lyrahealth-inc/ui-core-crossplatform'

import './providerCalendar.scss'
import { OAUTH_SUCCESS_RESPONSE } from './constants'
import { getCalendarEvents, getCalendarToken, getOAuthURL } from './data/calendarActions'
import { getCalendarRequiresAuthorization } from './data/calendarSelectors'
import { SETTINGS } from '../common/constants/routingConstants'
import { getAuthUserEmail, getAuthUserId } from '../data/auth/authSelectors'

const CurrentCalendar: FunctionComponent<CurrentCalendarProps> = ({
  actions: { getCalendarEvents, getOAuthURL, getCalendarToken },
}) => {
  const { isInProductCalendarEnabled } = useFlags()
  const userId = useSelector(getAuthUserId)
  const userEmail = useSelector(getAuthUserEmail)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'America/Los_Angeles'
  const requiresAuth = useSelector(getCalendarRequiresAuthorization)
  const navigate = useNavigate()

  useFetcher([[getCalendarToken, { providerId: userId }, !!userId]], [userId])

  const startOAuthFlow = useCallback(() => {
    if (!userEmail || !userId) {
      return
    }
    getOAuthURL({
      providerId: userId,
      providerEmail: userEmail,
      timeZone,
    }).then(({ data: url }) => {
      window.open(url)
    })
  }, [getOAuthURL, timeZone, userEmail, userId])

  useEffect(() => {
    const onMessage = (event: MessageEvent<any>) => {
      if (event.origin !== window.location.origin) {
        return
      }
      if (typeof event.data !== 'string') {
        return
      }

      if (event.data === OAUTH_SUCCESS_RESPONSE) {
        getCalendarToken({ providerId: userId })
      }
    }
    window.addEventListener('message', onMessage)
    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [getCalendarToken, userId])

  const getEventsForRange = useCallback(
    async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
      const tzOffset = getTimezoneOffset(timeZone)
      const { data: events } = await getCalendarEvents({
        startDate: addMilliseconds(parseISO(startDate), tzOffset).toISOString(),
        endDate: addMilliseconds(parseISO(endDate), tzOffset).toISOString(),
        providerId: userId,
      })
      return events.map((event) => ({
        id: event.id,
        title: event.title,
        start: addMilliseconds(parseISO(event.start_datetimetz), tzOffset).toISOString(),
        end: addMilliseconds(parseISO(event.end_datetimetz), tzOffset).toISOString(),
        extendedProps: {
          lyraProgramId: 'deb574e4-6aa3-4b47-bd21-d8be3ba5d42b',
          appointmentClass: 'initial',
          lyraAppointmentId: '22l1efa3ruk9roc89m1gi6irk4',
          clientFirstName: 'Aaron',
          clientLastName: 'Test',
          clientPhoneNumber: '+15103292801',
          appointmentSessionNumber: 1,
          appointmentMeetingFormat: MEETING_FORMATS.VIDEO,
          clientTimeZone: 'America/Los_Angeles',
          appointmentProviderPatientId: '5ea71d1f-4cbf-49e6-969e-619ab49e7275',
          lyraEventType: 'session',
        },
      }))
    },
    [getCalendarEvents, timeZone, userId],
  )

  if (!isInProductCalendarEnabled) {
    return null
  }
  return (
    <div className='lc-calendar'>
      <ProviderCalendar
        timeZone={timeZone}
        getEvents={getEventsForRange}
        loading={requiresAuth === undefined}
        onAuthorizePressed={startOAuthFlow}
        requiresAuth={requiresAuth}
        onSettingsPressed={() => navigate(SETTINGS.route)}
      />
    </div>
  )
}

export type CurrentCalendarProps = {
  actions: {
    getOAuthURL: (params: Parameters<typeof getOAuthURL>[0]) => Promise<{ data: string }>
    getCalendarToken: (params: Parameters<typeof getCalendarToken>[0]) => Promise<any>
    getCalendarEvents: (params: Parameters<typeof getCalendarEvents>[0]) => Promise<{ data: ProviderCalendarEvent[] }>
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getCalendarEvents: getCalendarEvents as any,
      getCalendarToken: getCalendarToken as any,
      getOAuthURL: getOAuthURL as any,
    },
    dispatch,
  ),
})

const connector = connect(null, mapDispatchToProps)

export default connector(CurrentCalendar)
