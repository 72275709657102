import { createSelector } from '@reduxjs/toolkit'

import calendarReducer from './calendarReducer'

export const getCalendarState = (state: Map<string, any>) =>
  state?.get('calendar') as ReturnType<typeof calendarReducer>

export const getCalendarRequiresAuthorization = createSelector(
  getCalendarState,
  (calendarState) => calendarState?.requiresAuthorization,
)
