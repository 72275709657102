import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import {
  ChevronIconDirection,
  ChevronV2Icon,
  GearIcon,
  SecondaryButton,
  Subhead,
  TertiaryIconButton,
} from '../../atoms'
import { SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'

export type ProviderCalendarHeaderProps = {
  onNextPressed: () => void
  onPrevPressed: () => void
  onTodayPressed: () => void
  onSettingsPressed: () => void
  rangeText: string
}

const Header = styled.View(({ theme }) => ({
  flexDirection: 'column',
  position: 'sticky',
  top: 0,
  backgroundColor: theme.colors.backgroundPrimary,
  zIndex: 3,
}))

const HeaderContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: theme.spacing['16px'],
  padding: `${theme.spacing['16px']} ${theme.spacing['24px']}`,
  backgroundColor: theme.colors.backgroundPrimary,
}))

const HeaderInnerContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['16px'],
}))

const Border = styled.View(({ theme }) => ({
  height: '1px',
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: '1px',
  backgroundColor: theme.colors.borderDefault,
}))

const IconButtonContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['8px'],
}))

const IconButton = styled(TertiaryIconButton)(({ theme }) => ({
  alignSelf: 'center',
  border: `1.5px solid ${theme.colors.borderDefault}`,
  height: '48px',
  width: '48px',
}))

export const ProviderCalendarHeader: FunctionComponent<ProviderCalendarHeaderProps> = ({
  onNextPressed,
  onPrevPressed,
  onTodayPressed,
  onSettingsPressed,
  rangeText,
}) => {
  const theme = useTheme() as ThemeType
  return (
    <Header>
      <HeaderContainer>
        <HeaderInnerContainer>
          <SecondaryButton
            style={{ borderColor: theme.colors.borderDefault }}
            customTextColor={theme.colors.textSecondary}
            text={<FormattedMessage defaultMessage='Today' description='Button text that moves calendar to today' />}
            onPress={onTodayPressed}
          />
          <IconButtonContainer>
            <IconButton leftIcon={<ChevronV2Icon direction={ChevronIconDirection.LEFT} />} onPress={onPrevPressed} />
            <IconButton leftIcon={<ChevronV2Icon direction={ChevronIconDirection.RIGHT} />} onPress={onNextPressed} />
          </IconButtonContainer>
          <Subhead text={rangeText} size={SubheadSize.LARGE} />
        </HeaderInnerContainer>
        <HeaderInnerContainer>
          <TertiaryIconButton
            leftIcon={<GearIcon color={theme.colors.iconDefault} size={20} />}
            onPress={onSettingsPressed}
          />
        </HeaderInnerContainer>
      </HeaderContainer>
      <Border />
    </Header>
  )
}
