// @intl project:provider-profile
import { defineMessage, defineMessages } from 'react-intl'

import { PROVIDER_LYRA_TYPES } from '../../models'
import { PrimaryNeedsWords } from '../searchForCare/constants'

export const REGISTERED_ACSW_CREDENTIAL = 'Registered Associate Clinical Social Worker (Registered ACSW)'

export enum ProviderTypeAbbreviated {
  BC = 'BC',
  DA = 'DA',
}

export enum ProviderTypesPluralEnum {
  COACHES = 'coaches',
  THERAPISTS = 'therapists',
  PHYSICIANS = 'physicians',
  PRESCRIBERS = 'prescribers',
  COUNSELLORS = 'counsellors',
  PROVIDERS = 'providers',
}

export const CALENDAR_SERVICE_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://calendar.service.lyrahealth.com'
    : process.env.NODE_ENV === 'prerelease'
    ? 'https://calendar.prerelease.lyrahealth.com'
    : 'https://calendar.staging.lyrahealth.com'

/**
 * Defines messages for values returned from PSS. Last updated with data from 2022-09-26.
 * This file should ideally be generated using the latest data from PSS. (LYRA-8415)
 *
 * Keys in the objects should match the values returned from PSS.
 */

/**
 * Provider credentials are currently stored as free-form text in PSS and not normalized.
 * This constant provides translations for _some_ of the well-formed credentials.
 */
export const PROVIDER_CREDENTIALS = Object.freeze(
  defineMessages({
    'Advanced Practice Registered Nurse with Psychiatric Specialty (APRN)': {
      defaultMessage: 'Advanced Practice Registered Nurse with Psychiatric Specialty (APRN)',
      description: "Provider Profile: Provider Credentials usually shown with the provider's name",
    },
    'Certified Professional Coach (CPC)': {
      defaultMessage: 'Certified Professional Coach (CPC)',
      description: "Provider Profile: Provider Credentials usually shown with the provider's name",
    },
    'Clinical Psychologist (PhD)': {
      defaultMessage: 'Clinical Psychologist (PhD)',
      description: "Provider Profile: Provider Credentials usually shown with the provider's name",
    },
    'Licensed Clinical Mental Health Counselor (CMHC)': {
      defaultMessage: 'Licensed Clinical Mental Health Counselor (CMHC)',
      description: "Provider Profile: Provider Credentials usually shown with the provider's name",
    },
    'Licensed Clinical Social Worker (LCSW)': {
      defaultMessage: 'Licensed Clinical Social Worker (LCSW)',
      description: "Provider Profile: Provider Credentials usually shown with the provider's name",
    },
    'Licensed Marriage and Family Therapist (LMFT)': {
      defaultMessage: 'Licensed Marriage and Family Therapist (LMFT)',
      description: "Provider Profile: Provider Credentials usually shown with the provider's name",
    },
    'Licensed Psychologist (PhD)': {
      defaultMessage: 'Licensed Psychologist (PhD)',
      description: "Provider Profile: Provider Credentials usually shown with the provider's name",
    },
    'Nurse Practitioner (NP)': {
      defaultMessage: 'Nurse Practitioner (NP)',
      description: "Provider Profile: Provider Credentials usually shown with the provider's name",
    },
    'Physician (MD)': {
      defaultMessage: 'Physician (MD)',
      description: "Provider Profile: Provider Credentials usually shown with the provider's name",
    },
    'Psychiatrist (MD)': {
      defaultMessage: 'Psychiatrist (MD)',
      description: "Provider Profile: Provider Credentials usually shown with the provider's name",
    },
    'Psychologist (PhD)': {
      defaultMessage: 'Psychologist (PhD)',
      description: "Provider Profile: Provider Credentials usually shown with the provider's name",
    },
    [REGISTERED_ACSW_CREDENTIAL]: {
      defaultMessage: 'Registered Associate Clinical Social Worker',
      description: "Provider Profile: Provider Credentials usually shown with the provider's name",
    },
  }),
)

export const PROVIDER_SENSITIVITIES = Object.freeze(
  defineMessages({
    LGB: {
      defaultMessage: 'LGB',
      description:
        'Provider Profile: Provider sensitivity: Used as one of many list items in the phrase: "Experience with {sensitivitiesList} clients"',
    },
    Transgender: {
      defaultMessage: 'transgender',
      description:
        'Provider Profile: Provider sensitivity: Used as one of many list items in the phrase: "Experience with {sensitivitiesList} clients"',
    },
    'Gay, Lesbian, Bisexual': {
      defaultMessage: 'gay, lesbian, bisexual',
      description:
        'Provider Profile: Provider sensitivity: Used as one of many list items in the phrase: "Experience with {sensitivitiesList} clients"',
    },
    Other: {
      defaultMessage: 'other',
      description:
        'Provider Profile: Provider sensitivity: Used as a one of many list items in the phrase: "Experience with {sensitivitiesList} clients"',
    },
  }),
)

/**
 * Contains provider conditions that will be renamed or removed.
 *
 * TODO: PROSPECT-3945
 * This object can be removed once PSS starts using the latest conditions in PROVIDER_CONDITIONS.
 * Before removing, please verify that PSS is no longer sending these in provider profiles!
 */
const PROVIDER_CONDITIONS_DEPRECATED = Object.freeze(
  defineMessages({
    //
    // Renamed or removed:
    //
    'Adoption and Reunion Issues': {
      defaultMessage: 'Adoption and Reunion Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Alcohol Abuse': {
      defaultMessage: 'Alcohol Abuse',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Bipolar Disorder': {
      defaultMessage: 'Bipolar Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Career Issues': {
      defaultMessage: 'Career Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Caregiver Issues': {
      defaultMessage: 'Caregiver Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Chronic Illness and Medical Issues': {
      defaultMessage: 'Chronic Illness and Medical Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Chronic Impulsivity': {
      defaultMessage: 'Chronic Impulsivity',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Developmental Disorders': {
      defaultMessage: 'Developmental Disorders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Digital Addiction': {
      defaultMessage: 'Digital Addiction',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Divorce: {
      defaultMessage: 'Divorce',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Domestic Violence and Abuse': {
      defaultMessage: 'Domestic Violence and Abuse',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Dual Diagnosis': {
      defaultMessage: 'Dual Diagnosis',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Emotional Disturbance': {
      defaultMessage: 'Emotional Disturbance',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'First Responders': {
      defaultMessage: 'First Responders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Gambling Addiction': {
      defaultMessage: 'Gambling Addiction',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Gender Identity': {
      defaultMessage: 'Gender Identity',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Insomnia: {
      defaultMessage: 'Insomnia',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Learning Disabilities': {
      defaultMessage: 'Learning Disabilities',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Marijuana Abuse': {
      defaultMessage: 'Marijuana Abuse',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Military and Veterans Issues': {
      defaultMessage: 'Military and Veterans Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Multicultural Issues': {
      defaultMessage: 'Multicultural Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Obsessive-Compulsive Disorder (OCD)': {
      defaultMessage: 'Obsessive-Compulsive Disorder (OCD)',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Pregnancy, Prenatal and Postpartum Issues': {
      defaultMessage: 'Pregnancy, Prenatal and Postpartum Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Schizophrenia: {
      defaultMessage: 'Schizophrenia',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Sex/Porn Addiction': {
      defaultMessage: 'Sex/Porn Addiction',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Sport Psychology': {
      defaultMessage: 'Sport Psychology',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Substance Abuse': {
      defaultMessage: 'Substance Abuse',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Suicidal Ideation': {
      defaultMessage: 'Suicidal Ideation',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Tobacco Use Disorder': {
      defaultMessage: 'Tobacco Use Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    "Tourette's Disorder": {
      defaultMessage: "Tourette's Disorder",
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Trauma: {
      defaultMessage: 'Trauma',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Workplace Issues': {
      defaultMessage: 'Workplace Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    "Women's Issues": {
      defaultMessage: "Women's Issues",
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    "Men's Issues": {
      defaultMessage: "Men's Issues",
      description: "Provider Profile: Name of a condition or provider's specialization",
    },

    //
    // "Items to remove from Salesforce":
    //
    'Abandonment Issues': {
      defaultMessage: 'Abandonment Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Abortion and Post Abortion Issues': {
      defaultMessage: 'Abortion and Post Abortion Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Acute Stress Disorder (ASD)': {
      defaultMessage: 'Acute Stress Disorder (ASD)',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Adjustment Disorder': {
      defaultMessage: 'Adjustment Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Adult Children of Alcoholics': {
      defaultMessage: 'Adult Children of Alcoholics',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Aging and Geriatric Issues': {
      defaultMessage: 'Aging and Geriatric Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Agitated Depression': {
      defaultMessage: 'Agitated Depression',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Antisocial Behavior': {
      defaultMessage: 'Antisocial Behavior',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Antisocial Personality Disorder': {
      defaultMessage: 'Antisocial Personality Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Attachment Issues': {
      defaultMessage: 'Attachment Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Atypical Depression': {
      defaultMessage: 'Atypical Depression',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Batterer Intervention': {
      defaultMessage: 'Batterer Intervention',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Behaviorism: {
      defaultMessage: 'Behaviorism',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Body Dysmorphic Disorder (BDD)': {
      defaultMessage: 'Body Dysmorphic Disorder (BDD)',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Childhood Depression': {
      defaultMessage: 'Childhood Depression',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Childhood-Onset Bipolar Disorder': {
      defaultMessage: 'Childhood-Onset Bipolar Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Chronic Fatigue Syndrome': {
      defaultMessage: 'Chronic Fatigue Syndrome',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Chronic Relapse': {
      defaultMessage: 'Chronic Relapse',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Codependency: {
      defaultMessage: 'Codependency',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Control Issues': {
      defaultMessage: 'Control Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Cognitive Disorders': {
      defaultMessage: 'Cognitive Disorders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Conversion Disorder': {
      defaultMessage: 'Conversion Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Coping Skills': {
      defaultMessage: 'Coping Skills',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Creative Blocks': {
      defaultMessage: 'Creative Blocks',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Delusional Disorder': {
      defaultMessage: 'Delusional Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Dependence: {
      defaultMessage: 'Dependence',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Dependent Personality Disorder': {
      defaultMessage: 'Dependent Personality Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Depersonalization Disorder': {
      defaultMessage: 'Depersonalization Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Digital Overuse': {
      defaultMessage: 'Digital Overuse',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Dissociative, Conversion, and Factitious Disorders': {
      defaultMessage: 'Dissociative, Conversion, and Factitious Disorders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Dissociative Conversion and Factitious Disorders': {
      defaultMessage: 'Dissociative Conversion and Factitious Disorders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Dyslexia: {
      defaultMessage: 'Dyslexia',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Dysthymia and Cyclothymia (Mood Disorders)': {
      defaultMessage: 'Dysthymia and Cyclothymia (Mood Disorders)',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Elder Abuse': {
      defaultMessage: 'Elder Abuse',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Elderly Persons Disorders': {
      defaultMessage: 'Elderly Persons Disorders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Emotional Intelligence': {
      defaultMessage: 'Emotional Intelligence',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Emptiness: {
      defaultMessage: 'Emptiness',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Exercise Addiction': {
      defaultMessage: 'Exercise Addiction',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Exhibitionism: {
      defaultMessage: 'Exhibitionism',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Familial Diseases': {
      defaultMessage: 'Familial Diseases',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Family of Origin Issues': {
      defaultMessage: 'Family of Origin Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Fear of Anger': {
      defaultMessage: 'Fear of Anger',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Firesetting: {
      defaultMessage: 'Firesetting',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Forensic Evaluations': {
      defaultMessage: 'Forensic Evaluations',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Forgiveness: {
      defaultMessage: 'Forgiveness',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Geriatric Depression': {
      defaultMessage: 'Geriatric Depression',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Habits: {
      defaultMessage: 'Habits',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Helplessness: {
      defaultMessage: 'Helplessness',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Homicidal Ideation': {
      defaultMessage: 'Homicidal Ideation',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Hypomania: {
      defaultMessage: 'Hypomania',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Identity Issues': {
      defaultMessage: 'Identity Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Individuation: {
      defaultMessage: 'Individuation',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Infant Mental Health': {
      defaultMessage: 'Infant Mental Health',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Intellectual Disability': {
      defaultMessage: 'Intellectual Disability',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Isolation: {
      defaultMessage: 'Isolation',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Jealousy: {
      defaultMessage: 'Jealousy',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Life Purpose': {
      defaultMessage: 'Life Purpose',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Memory Disorders': {
      defaultMessage: 'Memory Disorders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Mood and Adjustment Disorder': {
      defaultMessage: 'Mood and Adjustment Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Mood Disorder Due to a General Medical Condition': {
      defaultMessage: 'Mood Disorder Due to a General Medical Condition',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Mood Disorders': {
      defaultMessage: 'Mood Disorders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Narcolepsy and Cataplexy': {
      defaultMessage: 'Narcolepsy and Cataplexy',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Obsessive-Compulsive Personality Disorder (OCPD)': {
      defaultMessage: 'Obsessive-Compulsive Personality Disorder (OCPD)',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Performance Enhancement': {
      defaultMessage: 'Performance Enhancement',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Personal Development': {
      defaultMessage: 'Personal Development',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Physical Abuse of Adult': {
      defaultMessage: 'Physical Abuse of Adult',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Pornography Addiction': {
      defaultMessage: 'Pornography Addiction',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Polyamory: {
      defaultMessage: 'Polyamory',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Power: {
      defaultMessage: 'Power',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Psychological Addiction': {
      defaultMessage: 'Psychological Addiction',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Psychosomatic: {
      defaultMessage: 'Psychosomatic',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Psychotic Depression': {
      defaultMessage: 'Psychotic Depression',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Reactive Attachment Disorder': {
      defaultMessage: 'Reactive Attachment Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Rejection: {
      defaultMessage: 'Rejection',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Runaways: {
      defaultMessage: 'Runaways',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Schizoid and Schizotypal Personality Disorder': {
      defaultMessage: 'Schizoid and Schizotypal Personality Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Schizoid Personality Disorder': {
      defaultMessage: 'Schizoid Personality Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Selective Mutism': {
      defaultMessage: 'Selective Mutism',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Self-Esteem': {
      defaultMessage: 'Self-Esteem',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Separation Anxiety': {
      defaultMessage: 'Separation Anxiety',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Sexual Desire Disorders': {
      defaultMessage: 'Sexual Desire Disorders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Shame: {
      defaultMessage: 'Shame',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Sleep-Walking': {
      defaultMessage: 'Sleep-Walking',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Somatic Issues': {
      defaultMessage: 'Somatic Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Somatization: {
      defaultMessage: 'Somatization',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Speech Language and Learning Disorders': {
      defaultMessage: 'Speech Language and Learning Disorders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Spirituality: {
      defaultMessage: 'Spirituality',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Suspiciousness and Paranoia': {
      defaultMessage: 'Suspiciousness and Paranoia',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Teen Violence': {
      defaultMessage: 'Teen Violence',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Thinking Disorders': {
      defaultMessage: 'Thinking Disorders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Trust Issues': {
      defaultMessage: 'Trust Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Workplace Depression': {
      defaultMessage: 'Workplace Depression',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Worthlessness: {
      defaultMessage: 'Worthlessness',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Young Adult Issues': {
      defaultMessage: 'Young Adult Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
  }),
)

export const PROVIDER_CONDITIONS = Object.freeze({
  ...PROVIDER_CONDITIONS_DEPRECATED,
  ...defineMessages({
    'Alcohol Use': {
      defaultMessage: 'Alcohol Use',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Anger Management': {
      defaultMessage: 'Anger Management',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Depression: {
      defaultMessage: 'Depression',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Relationship Issues': {
      defaultMessage: 'Relationship Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Trauma and PTSD': {
      defaultMessage: 'Trauma and PTSD',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Autism Spectrum Disorder': {
      defaultMessage: 'Autism Spectrum Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Borderline Personality Disorder': {
      defaultMessage: 'Borderline Personality Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Eating Disorders': {
      defaultMessage: 'Eating Disorders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Schizophrenia Spectrum Disorders': {
      defaultMessage: 'Schizophrenia Spectrum Disorders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Self-Harm': {
      defaultMessage: 'Self-Harm',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Anxiety: {
      defaultMessage: 'Anxiety',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Attention Deficit Hyperactivity Disorder (ADHD)': {
      defaultMessage: 'Attention Deficit Hyperactivity Disorder (ADHD)',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Behavioral Issues': {
      defaultMessage: 'Behavioral Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Bipolar Disorders': {
      defaultMessage: 'Bipolar Disorders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Sleep Issues': {
      defaultMessage: 'Sleep Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Obsessive-Compulsive Disorder': {
      defaultMessage: 'Obsessive-Compulsive Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Panic: {
      defaultMessage: 'Panic',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Stress: {
      defaultMessage: 'Stress',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Life Coaching': {
      defaultMessage: 'Life Coaching',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Life Transitions': {
      defaultMessage: 'Life Transitions',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Perfectionism: {
      defaultMessage: 'Perfectionism',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Workplace Issues': {
      defaultMessage: 'Workplace Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Communication Problems': {
      defaultMessage: 'Communication Problems',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Grief: {
      defaultMessage: 'Grief',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Adoption/Fostering': {
      defaultMessage: 'Adoption/Fostering',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Gambling: {
      defaultMessage: 'Gambling',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Multicultural Factors': {
      defaultMessage: 'Multicultural Factors',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Blended Family Issues': {
      defaultMessage: 'Blended Family Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Phobias: {
      defaultMessage: 'Phobias',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Breakup, Separation, or Divorce': {
      defaultMessage: 'Breakup, Separation, or Divorce',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Family Conflict': {
      defaultMessage: 'Family Conflict',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Parenting Issues': {
      defaultMessage: 'Parenting Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Child or Adolescent Issues': {
      defaultMessage: 'Child or Adolescent Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Domestic or Intimate Partner Violence': {
      defaultMessage: 'Domestic or Intimate Partner Violence',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Drug Use': {
      defaultMessage: 'Drug Use',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Peer Relationships': {
      defaultMessage: 'Peer Relationships',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Emotion Management': {
      defaultMessage: 'Emotion Management',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Sexual Assault and Abuse': {
      defaultMessage: 'Sexual Assault and Abuse',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Suicidal Thoughts': {
      defaultMessage: 'Suicidal Thoughts',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Personality Disorders': {
      defaultMessage: 'Personality Disorders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Work Stress': {
      defaultMessage: 'Work Stress',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Academic Concerns': {
      defaultMessage: 'Academic Concerns',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Infidelity: {
      defaultMessage: 'Infidelity',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Sexual Issues': {
      defaultMessage: 'Sexual Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Gender Dysphoria': {
      defaultMessage: 'Gender Dysphoria',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Sexual Orientation': {
      defaultMessage: 'Sexual Orientation',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Body Image Issues': {
      defaultMessage: 'Body Image Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Chronic Illness or Medical Issues': {
      defaultMessage: 'Chronic Illness or Medical Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Chronic Pain': {
      defaultMessage: 'Chronic Pain',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Pregnancy, Prenatal, and Postpartum Challenges': {
      defaultMessage: 'Pregnancy, Prenatal, and Postpartum Challenges',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Learning Disabilities/Differences': {
      defaultMessage: 'Learning Disabilities/Differences',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Fertility Issues': {
      defaultMessage: 'Fertility Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Oppositional Defiant Disorder': {
      defaultMessage: 'Oppositional Defiant Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Weight Loss': {
      defaultMessage: 'Weight Loss',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    "Women's Mental Health": {
      defaultMessage: "Women's Mental Health",
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Neurodevelopmental Disorders': {
      defaultMessage: 'Neurodevelopmental Disorders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Hoarding: {
      defaultMessage: 'Hoarding',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    "Men's Mental Health": {
      defaultMessage: "Men's Mental Health",
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Relationships: {
      defaultMessage: 'Relationships',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Digital/Gaming Addiction': {
      defaultMessage: 'Digital/Gaming Addiction',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Pregnancy Prenatal and Postpartum Issues': {
      defaultMessage: 'Pregnancy Prenatal and Postpartum Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Sex/Pornography Addiction': {
      defaultMessage: 'Sex/Pornography Addiction',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    "Tourette's/Tic Disorders": {
      defaultMessage: "Tourette's/Tic Disorders",
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Trichotillomania: {
      defaultMessage: 'Trichotillomania',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Bullying: {
      defaultMessage: 'Bullying',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'None of the Above': {
      defaultMessage: 'None of the Above',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Sports Performance': {
      defaultMessage: 'Sports Performance',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Chronic Impulsivity and Addictions': {
      defaultMessage: 'Chronic Impulsivity and Addictions',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Caregiver Stress': {
      defaultMessage: 'Caregiver Stress',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'First-Responders': {
      defaultMessage: 'First-Responders',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Cannabis Use': {
      defaultMessage: 'Cannabis Use',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Active Military/Veterans': {
      defaultMessage: 'Active Military/Veterans',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Opioid Dependence': {
      defaultMessage: 'Opioid Dependence',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Agoraphobia: {
      defaultMessage: 'Agoraphobia',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Autism Spectrum Discorder': {
      defaultMessage: 'Autism Spectrum Discorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Financial Issues': {
      defaultMessage: 'Financial Issues',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Marital / Premarital/Partnering': {
      defaultMessage: 'Marital / Premarital/Partnering',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Sexual Behavioral Health/Sexual Satisfaction': {
      defaultMessage: 'Sexual Behavioral Health/Sexual Satisfaction',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Nicotine Use': {
      defaultMessage: 'Nicotine Use',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Paranoid Personality Disorder': {
      defaultMessage: 'Paranoid Personality Disorder',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Couples: {
      defaultMessage: 'Couples',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    Neurodivergence: {
      defaultMessage: 'Neurodivergence',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
    'Transgender Mental Health': {
      defaultMessage: 'Transgender Mental Health',
      description: "Provider Profile: Name of a condition or provider's specialization",
    },
  }),
})

/**
 * Display names describing the provider's title that will be appended to tooltip text
 * in the ProviderSupportedModalities component
 */
export const PROVIDER_DISPLAY_NAMES = Object.freeze(
  defineMessages({
    [PROVIDER_LYRA_TYPES.COACH]: {
      defaultMessage: 'coach',
      description: "Provider display name: Name of the provider's title",
    },
    [PROVIDER_LYRA_TYPES.LYRATHERAPIST]: {
      defaultMessage: 'therapist',
      description: "Provider display name: Name of the provider's title",
    },
    [PROVIDER_LYRA_TYPES.PROVIDER]: {
      defaultMessage: 'provider',
      description: "Provider display name: Name of the provider's title",
    },
    [PROVIDER_LYRA_TYPES['PSYCHIATRIC NURSE PRACTITIONER']]: {
      defaultMessage: 'provider',
      description: "Provider display name: Name of the provider's title",
    },
    [PROVIDER_LYRA_TYPES.PSYCHIATRIST]: {
      defaultMessage: 'provider',
      description: "Provider display name: Name of the provider's title",
    },
    [PROVIDER_LYRA_TYPES.THERAPIST]: {
      defaultMessage: 'therapist',
      description: "Provider display name: Name of the provider's title",
    },
    [PROVIDER_LYRA_TYPES.LYRAPRESCRIBER]: {
      defaultMessage: 'physician',
      description: "Provider display name: Name of the provider's title",
    },
    [PROVIDER_LYRA_TYPES.LYRAFELLOW]: {
      defaultMessage: 'therapist',
      description: "Provider display name: Name of the provider's title",
    },
    [PROVIDER_LYRA_TYPES.LYRACLINICALLEAVEEVALUATOR]: {
      defaultMessage: 'Counselor',
      description: "Provider display name: Name of the provider's title",
    },
  }),
)

export const LYRA_CARE_PROVIDER_TEXT = {
  HEADER: defineMessage({
    defaultMessage: '{providerFirstName} offers guided care between sessions',
    description: 'Label text about LC provider offering guided care between sessions',
  }),
  SUBHEADER: defineMessage({
    defaultMessage: 'You’ll have access to personalized care and resources between your live sessions.',
    description: 'Label text about LC provider providing care and resources',
  }),
}

/**
 * Map primary needs to values that can be used in a formatted message select function
 */
export const PROVIDER_PRIMARY_NEED_DISPLAY_KEYS = Object.freeze({
  [PrimaryNeedsWords.DEPRESSION_SADNESS]: 'depression',
  [PrimaryNeedsWords.ANXIETY]: 'anxiety',
  [PrimaryNeedsWords.STRESS]: 'stress',
  [PrimaryNeedsWords.ALCOHOL_DRUG_USE]: 'alcohol',
  [PrimaryNeedsWords.RELATIONSHIPS_AND_FAMILY]: 'relationships',
  [PrimaryNeedsWords.AUTISM_SPECTRUM]: 'autism',
  [PrimaryNeedsWords.SCHOOL]: 'school',
  [PrimaryNeedsWords.BEHAVIOR_PROBLEMS]: 'behavior',
  [PrimaryNeedsWords.DEVELOPMENTAL_CONCERNS]: 'developmental',
  [PrimaryNeedsWords.ADHD]: 'adhd',
})
