import React, { FunctionComponent, useMemo } from 'react'
import { FormattedDateTimeRange, FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import {
  CalendarExtendedProps,
  formatPhoneNumberForDisplay,
  getProgramNameFromId,
  getProgramNameLabel,
  getTimeZoneName,
  MEETING_FORMATS,
} from '@lyrahealth-inc/shared-app-logic'

import {
  BodyText,
  ChatLiveIcon,
  ClockIcon,
  CloseIcon,
  Headline,
  Link,
  LocationIcon,
  PhoneIcon,
  PressableOpacity,
  Subhead,
  VideoIcon,
} from '../../atoms'
import { BodyTextSize, HeadlineSize, SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'

export type ProviderCalendarPopoverProps = {
  startDate: string
  endDate: string
  extendedProps: CalendarExtendedProps
  timeZone: string
  onClientProfilePressed?: (patientProviderRelId: string) => void
  onClose?: () => void
}

const Container = styled.View(({ theme }) => ({
  alignSelf: 'flex-start',
  backgroundColor: theme.colors.backgroundPrimary,
  borderRadius: '16px',
  boxShadow: `0 2px 16px ${theme.colors.shadowLow}`,
  paddingBottom: theme.spacing['24px'],
  width: '450px',
  gap: theme.spacing['16px'],
}))

const CloseButton = styled(PressableOpacity)({
  alignSelf: 'flex-end',
})

const Header = styled.View(({ theme }) => ({
  gap: theme.spacing['4px'],
  borderBottomColor: theme.colors.borderDefault,
  borderBottomWidth: '1px',
  padding: `${theme.spacing['24px']} ${theme.spacing['24px']} ${theme.spacing['16px']} ${theme.spacing['24px']}`,
}))

const Section = styled.View(({ theme }) => ({
  padding: `0px ${theme.spacing['24px']}`,
  gap: theme.spacing['8px'],
}))

const SectionItem = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['16px'],
}))

const SectionHeader = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
})

export const ProviderCalendarPopover: FunctionComponent<ProviderCalendarPopoverProps> = ({
  startDate,
  endDate,
  extendedProps,
  timeZone,
  onClose,
  onClientProfilePressed,
}) => {
  const theme = useTheme() as ThemeType

  const eventTitle = useMemo(() => {
    const programName = getProgramNameFromId(extendedProps.lyraProgramId)
    const programNameLabel = programName ? getProgramNameLabel(programName) : ''
    if (extendedProps.appointmentClass === 'initial') {
      return (
        <FormattedMessage
          defaultMessage='{firstName} {lastName} • Intake • {programName}'
          description='Event title for lyra appointment'
          values={{
            firstName: extendedProps.clientFirstName,
            lastName: extendedProps.clientLastName,
            programName: programNameLabel,
          }}
        />
      )
    }
    return (
      <FormattedMessage
        defaultMessage='{firstName} {lastName} • Session {sessionNumber} • {programName}'
        description='Event title for lyra appointment'
        values={{
          firstName: extendedProps.clientFirstName,
          lastName: extendedProps.clientLastName,
          sessionNumber: extendedProps.appointmentSessionNumber,
          programName: programNameLabel,
        }}
      />
    )
  }, [
    extendedProps.appointmentClass,
    extendedProps.appointmentSessionNumber,
    extendedProps.clientFirstName,
    extendedProps.clientLastName,
    extendedProps.lyraProgramId,
  ])

  const meetingFormatIcon = useMemo(() => {
    switch (extendedProps.appointmentMeetingFormat) {
      case MEETING_FORMATS.IN_PERSON: {
        return <LocationIcon fillColor={theme.colors.iconDefault} size={24} />
      }
      case MEETING_FORMATS.PHONE:
      case MEETING_FORMATS.CALL: {
        return <PhoneIcon fillColor={theme.colors.iconDefault} size={24} />
      }
      case MEETING_FORMATS.LIVE_MESSAGING: {
        return <ChatLiveIcon fillColor={theme.colors.iconDefault} size={24} />
      }
      default: {
        return <VideoIcon fillColor={theme.colors.iconDefault} size={24} />
      }
    }
  }, [extendedProps.appointmentMeetingFormat, theme.colors.iconDefault])
  return (
    <Container>
      <Header>
        <CloseButton onPress={onClose}>
          <CloseIcon size={16} />
        </CloseButton>
        <Subhead size={SubheadSize.MEDIUM} text={eventTitle} />
      </Header>
      <Section>
        <Headline
          size={HeadlineSize.EYEBROW}
          text={<FormattedMessage defaultMessage='Session Details' description='Heading for session details' />}
        />
        <SectionItem>
          {meetingFormatIcon}
          <BodyText
            text={
              <FormattedDateTimeRange
                from={new Date(startDate)}
                to={new Date(endDate)}
                weekday='long'
                month='long'
                day='numeric'
                hour='numeric'
                minute='numeric'
                timeZoneName='short'
                timeZone={timeZone}
              />
            }
            size={BodyTextSize.SMALL}
          />
        </SectionItem>
      </Section>
      <Section>
        <SectionHeader>
          <Headline
            size={HeadlineSize.EYEBROW}
            text={<FormattedMessage defaultMessage='Client Details' description='Section of client details' />}
          />
          <Link
            underline
            onPress={() => onClientProfilePressed?.(extendedProps.appointmentProviderPatientId)}
            text={<FormattedMessage defaultMessage='Client profile' description='Link to client profile' />}
          />
        </SectionHeader>
        {extendedProps.clientTimeZone && (
          <SectionItem>
            <ClockIcon size={24} fillColor={theme.colors.iconDefault} />
            <BodyText
              text={
                <FormattedMessage
                  defaultMessage='Timezone: {timeZone}'
                  values={{
                    timeZone: getTimeZoneName({ timeZone: extendedProps.clientTimeZone, timeZoneName: 'short' }),
                  }}
                  description='Client timezone displayed on event'
                />
              }
              size={BodyTextSize.SMALL}
            />
          </SectionItem>
        )}
        {extendedProps.clientPhoneNumber && (
          <SectionItem>
            <PhoneIcon size={24} fillColor={theme.colors.iconDefault} />
            <BodyText
              text={
                <FormattedMessage
                  defaultMessage='{phoneNumber} — Dial *88 to block your number'
                  values={{ phoneNumber: formatPhoneNumberForDisplay(extendedProps.clientPhoneNumber) }}
                  description='Phone number displayed on event'
                />
              }
              size={BodyTextSize.SMALL}
            />
          </SectionItem>
        )}
      </Section>
    </Container>
  )
}
