import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { View } from 'react-native'

import { DatesSetArg, DayHeaderContentArg, EventContentArg, EventInput, SlotLabelContentArg } from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridWeek from '@fullcalendar/timegrid'
import { addMinutes, subMinutes } from 'date-fns'
import { format } from 'date-fns-tz'
import styled, { useTheme } from 'styled-components/native'

import { getTimeZoneName } from '@lyrahealth-inc/shared-app-logic'

import { BodyText, LoadingIndicator, Subhead } from '../../atoms'
import { ProviderCalendarAuthorizationCard, ProviderCalendarEvent, ProviderCalendarHeader } from '../../molecules'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'

export type ProviderCalendarProps = {
  timeZone?: string
  onAuthorizePressed?: () => void
  onSettingsPressed?: () => void
  loading?: boolean
  requiresAuth?: boolean
  getEvents?: (params: { startDate: string; endDate: string }) => Promise<EventInput[]>
}

const Container = styled.View<{ lowOpacity: boolean }>(({ lowOpacity }) => ({
  opacity: lowOpacity ? 0.4 : 1,
  height: 'calc(100vh - 63px)',
  overflow: 'scroll',
}))

const DayContentContainer = styled.View<{ isToday: boolean }>(({ theme, isToday }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing['4px'],
  padding: theme.spacing['12px'],
  borderBottomWidth: isToday ? '4px' : 0,
  borderBottomColor: theme.colors.borderFocus,
  width: '100%',
}))

const LoadingView = styled.View({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '100%',
  zIndex: 2,
  justifyContent: 'center',
})

export const ProviderCalendar: FunctionComponent<ProviderCalendarProps> = ({
  onAuthorizePressed,
  onSettingsPressed,
  timeZone = 'America/Los_Angeles',
  loading = false,
  requiresAuth = false,
  getEvents,
}) => {
  const intl = useIntl()
  const theme = useTheme() as ThemeType
  const ref = useRef<FullCalendar>(null)

  const [dateRange, setDateRange] = useState<{ start: Date; end: Date } | null>(null)

  const onDatesSet = useCallback((dates: DatesSetArg) => {
    setDateRange(dates)
  }, [])

  const dayContentRenderer = useCallback(
    ({ date, isToday }: DayHeaderContentArg) => {
      const newDate = addMinutes(date, date.getTimezoneOffset())
      return (
        <DayContentContainer isToday={isToday}>
          <Subhead
            size={SubheadSize.XSMALL}
            text={format(newDate, 'eee d', { timeZone })}
            color={isToday ? theme.colors.textActive : undefined}
          />
        </DayContentContainer>
      )
    },
    [theme.colors.textActive, timeZone],
  )

  const slotLabelContent = useCallback(
    ({ text }: SlotLabelContentArg) => {
      return <BodyText color={theme.colors.textSecondary} size={BodyTextSize.SMALL} text={text} />
    },
    [theme.colors.textSecondary],
  )

  const eventContent = useCallback(
    (args: EventContentArg) => {
      return <ProviderCalendarEvent args={args} startingRange={null} timeZone={timeZone} />
    },
    [timeZone],
  )

  useEffect(() => {
    if (!ref.current) {
      return
    }
    const calendar = ref.current.getApi()
    setDateRange({ start: calendar.view.currentStart, end: calendar.view.currentEnd })
    calendar.on('datesSet', onDatesSet)

    return () => {
      calendar.off('datesSet', onDatesSet)
    }
  }, [onDatesSet])

  const rangeText = useMemo(() => {
    if (!dateRange) {
      return ''
    }

    const startDate = addMinutes(dateRange.start, dateRange.start.getTimezoneOffset())
    const endDate = subMinutes(addMinutes(dateRange.end, dateRange.end.getTimezoneOffset()), 1)
    const startMonth = intl.formatDate(startDate, { month: 'short', timeZone })
    const endMonth = intl.formatDate(endDate, { month: 'short', timeZone })
    const startYear = intl.formatDate(startDate, { year: 'numeric', timeZone })
    const endYear = intl.formatDate(endDate, { year: 'numeric', timeZone })
    if (startMonth === endMonth && startYear === endYear) {
      return intl.formatDate(endDate, { month: 'long', year: 'numeric', timeZone })
    }
    const startDateFormatted = intl.formatDate(startDate, {
      month: 'short',
      year: startYear === endYear ? undefined : 'numeric',
      timeZone,
    })

    const endDateFormatted = intl.formatDate(endDate, { month: 'short', year: 'numeric', timeZone })
    return `${startDateFormatted} - ${endDateFormatted}`
  }, [dateRange, intl, timeZone])

  useEffect(() => {
    document.getElementsByClassName('fc-timegrid-now-indicator-line').item(0)?.scrollIntoView({ block: 'center' })
  }, [])
  return (
    <View>
      <Container lowOpacity={loading}>
        <ProviderCalendarHeader
          rangeText={rangeText}
          onNextPressed={() => ref.current?.getApi().next()}
          onPrevPressed={() => ref.current?.getApi().prev()}
          onTodayPressed={() => ref.current?.getApi().today()}
          onSettingsPressed={() => onSettingsPressed?.()}
        />
        <FullCalendar
          ref={ref}
          headerToolbar={false}
          plugins={[timeGridWeek, interactionPlugin]}
          initialView='timeGridWeek'
          eventSources={[
            {
              events: (info, _success, _failure) => {
                if (!getEvents) {
                  return []
                }
                return getEvents({ startDate: info.start.toISOString(), endDate: info.end.toISOString() })
              },
            },
          ]}
          allDaySlot={false}
          dayHeaderContent={dayContentRenderer}
          nowIndicator
          slotLabelContent={slotLabelContent}
          eventColor='transparent'
          expandRows={true}
          contentHeight={2000}
          stickyHeaderDates={true}
          eventContent={eventContent}
          timeZone={timeZone}
          viewDidMount={({ el }) => {
            const axisEl = el.querySelector('.fc-timegrid-axis-frame')
            if (axisEl) {
              axisEl.innerHTML = getTimeZoneName({ timeZone, timeZoneName: 'short' }) ?? ''
            }
          }}
        />
      </Container>
      {loading && (
        <LoadingView>
          <LoadingIndicator topPadding={0} />
        </LoadingView>
      )}
      {requiresAuth && !loading && <ProviderCalendarAuthorizationCard onAuthorizePressed={onAuthorizePressed} />}
    </View>
  )
}
