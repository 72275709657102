import React, { FunctionComponent, useCallback, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'

import { addDays, addHours, parseISO } from 'date-fns'
import styled, { useTheme } from 'styled-components/native'

import { getTimeZoneName } from '@lyrahealth-inc/shared-app-logic'

import {
  AlertIconStroke,
  BodyText,
  CheckIcon,
  EditIconBoxed,
  InfoIcon,
  PrimaryButton,
  SecondaryButton,
  Subhead,
  Tooltip,
} from '../../atoms'
import { ProviderBookableCalendar, ProviderBookableCalendarRef } from '../../organisms'
import { SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'

export type ProviderBookableCalendarSettingsProps = {
  timeZone?: string
}

const Container = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  border: `1px solid ${theme.colors.borderDefault}`,
  padding: theme.spacing['24px'],
  borderRadius: '16px',
  gap: theme.spacing['24px'],
}))

const Header = styled.View(({ theme }) => ({
  gap: theme.spacing['16px'],
}))

const HeaderInner = styled.View(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottomWidth: '1px',
  borderBottomColor: theme.colors.borderDefault,
  paddingBottom: theme.spacing['16px'],
}))

const Row = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const AvailableSpots = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['12px'],
}))

const AvailableSpotsAmount = styled.View<{ showWarning: boolean }>(({ theme, showWarning }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['8px'],
  border: `1px solid ${theme.colors.borderDefault}`,
  borderRadius: '8px',
  padding: theme.spacing['4px'],
  backgroundColor: showWarning ? theme.colors.backgroundWarning : theme.colors.backgroundSuccess,
}))

const CalendarContainer = styled.View(({ theme }) => ({
  maxHeight: '655px',
  overflow: 'scroll',
  borderRadius: '16px',
  border: `1px solid ${theme.colors.borderDefault}`,
}))

const ButtonGroup = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['16px'],
}))

const getInitialEvents = () => {
  const startTime = parseISO('2024-09-30T17:00:00Z')
  const arr: { startTime: string; endTime: string }[] = []
  for (let i = 0; i < 5; i++) {
    for (let j = 0; j < 6; j++) {
      const start = addHours(addDays(startTime, i), j > 2 ? j + 1 : j)
      arr.push({
        startTime: start.toISOString(),
        endTime: addHours(start, 1).toISOString(),
      })
    }
  }
  return arr
}

export const ProviderBookableCalendarSettings: FunctionComponent<ProviderBookableCalendarSettingsProps> = ({
  timeZone = 'America/Los_Angeles',
}) => {
  const calendarRef = useRef<ProviderBookableCalendarRef>(null)
  const theme = useTheme() as ThemeType
  const [events, setEvents] = useState<{ startTime: string; endTime: string }[]>([])
  const [isEditMode, setIsEditMode] = useState(false)
  const [savedEvents, setSavedEvents] = useState<{ startTime: string; endTime: string }[]>(getInitialEvents())
  const onSave = useCallback(() => {
    setSavedEvents(events)
    setIsEditMode(false)
  }, [events])

  const showWarning = events.length !== 30
  return (
    <Container>
      <Header>
        <HeaderInner>
          <View>
            <Subhead
              size={SubheadSize.MEDIUM}
              text={
                <FormattedMessage
                  defaultMessage='Recurring availability'
                  description='Header for setting calendar availability'
                />
              }
            />
            <BodyText
              color={theme.colors.textSecondary}
              text={
                <FormattedMessage
                  defaultMessage='Clients can see and book these times'
                  description='Subhead for setting calendar availability'
                />
              }
            />
          </View>
          <Tooltip
            wrapperStyle={{
              flexGrow: 0,
              alignItems: 'flex-start',
            }}
            hoverEnabled
            content='Tooltip content here'
            placement='right'
          >
            <InfoIcon size={24} />
          </Tooltip>
        </HeaderInner>
        <Row>
          <AvailableSpots>
            <AvailableSpotsAmount showWarning={showWarning}>
              {showWarning ? (
                <AlertIconStroke fillColor={theme.colors.iconWarning} size={24} />
              ) : (
                <CheckIcon fillColor={theme.colors.iconSuccess} size={24} />
              )}
              <Subhead
                size={SubheadSize.LARGE}
                text={
                  <FormattedMessage
                    defaultMessage='{availableSpots} of {targetSpots}'
                    description='Text for available spots'
                    values={{ availableSpots: events.length, targetSpots: 30 }}
                  />
                }
                color={showWarning ? theme.colors.textWarning : theme.colors.textSuccess}
              />
            </AvailableSpotsAmount>
            <BodyText
              text={<FormattedMessage defaultMessage='Available spots' description='Text for available spots' />}
            />
          </AvailableSpots>
          {isEditMode ? (
            <ButtonGroup>
              <SecondaryButton
                text={<FormattedMessage defaultMessage='Cancel' description='Button to cancel editing calendar' />}
                onPress={() => {
                  setIsEditMode(false)
                  calendarRef.current?.reset()
                }}
              />
              <PrimaryButton
                text={<FormattedMessage defaultMessage='Save' description='Button to save calendar' />}
                onPress={onSave}
              />
            </ButtonGroup>
          ) : (
            <SecondaryButton
              text={<FormattedMessage defaultMessage='Edit' description='Button to edit calendar' />}
              onPress={() => setIsEditMode(true)}
              leftIcon={<EditIconBoxed size={24} />}
            />
          )}
        </Row>
      </Header>
      <CalendarContainer>
        <ProviderBookableCalendar
          ref={calendarRef}
          timeZone={timeZone}
          onEventsChanged={setEvents}
          disableEditing={!isEditMode}
          initialEvents={savedEvents}
        />
      </CalendarContainer>
      <Row>
        <BodyText
          color={theme.colors.textSecondary}
          text={
            <FormattedMessage
              defaultMessage='Timezone: {timeZone}'
              description='Timezone of provider calendar'
              values={{ timeZone: getTimeZoneName({ timeZone, timeZoneName: 'short' }) }}
            />
          }
        />
      </Row>
    </Container>
  )
}
