import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'

import {
  DateSelectArg,
  DateSpanApi,
  DayHeaderContentArg,
  EventContentArg,
  SlotLabelContentArg,
} from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridWeek from '@fullcalendar/timegrid'
import { addHours, addMinutes, differenceInMinutes, parseISO, setDay, startOfDay, subMilliseconds } from 'date-fns'
import { format, getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import styled, { useTheme } from 'styled-components/native'

import { getAdjustedEvent } from '@lyrahealth-inc/shared-app-logic'

import { BodyText, Subhead } from '../../atoms'
import { ProviderCalendarEvent } from '../../molecules'
import { BodyTextSize, SubheadSize } from '../../styles'
import { ThemeType } from '../../utils'

export type ProviderBookableCalendarProps = {
  timeZone?: string
  onEventsChanged?: (events: { startTime: string; endTime: string }[]) => void
  disableEditing?: boolean
  initialEvents?: { startTime: string; endTime: string }[]
}

export type ProviderBookableCalendarRef = {
  reset: () => void
}

const DayContentContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['4px'],
}))

function eventsOverlap(a: { start: Date; end: Date }, b: { start: Date; end: Date }): boolean {
  if (a.start < b.start && b.start < a.end) return true
  if (a.start < b.end && b.end < a.end) return true // b ends in a
  if (b.start <= a.start && a.end <= b.end) return true // a in b
  return false
}

export const ProviderBookableCalendar = forwardRef<ProviderBookableCalendarRef, ProviderBookableCalendarProps>(
  ({ timeZone = 'America/Los_Angeles', onEventsChanged, disableEditing = false, initialEvents = [] }, ref) => {
    const calendarRef = useRef<FullCalendar>(null)
    const theme = useTheme() as ThemeType
    const startingRange = useRef<{ startStr: string; endStr: string } | null>(null)

    const dayContentRenderer = useCallback(
      ({ date }: DayHeaderContentArg) => {
        const newDate = addMinutes(date, date.getTimezoneOffset())
        return (
          <DayContentContainer>
            <Subhead size={SubheadSize.XSMALL} text={format(newDate, 'eeee', { timeZone })} />
          </DayContentContainer>
        )
      },
      [timeZone],
    )

    const slotLabelContent = useCallback(
      ({ text }: SlotLabelContentArg) => {
        return <BodyText color={theme.colors.textSecondary} size={BodyTextSize.SMALL} text={text} />
      },
      [theme.colors.textSecondary],
    )

    const eventContent = useCallback(
      (args: EventContentArg) => {
        return <ProviderCalendarEvent args={args} startingRange={startingRange} disableEditing={disableEditing} />
      },
      [disableEditing],
    )

    const onSelect = useCallback((event: DateSelectArg) => {
      if (!event.start || !event.end) {
        return
      }
      const adjustedEvent = getAdjustedEvent(event, startingRange.current)
      let startTime = parseISO(adjustedEvent.start)
      const endTime = parseISO(adjustedEvent.end)
      const differenceHours = differenceInMinutes(endTime, startTime) / 60
      const numEvents = Math.ceil(differenceHours)
      for (let i = 0; i < numEvents; i++) {
        calendarRef.current?.getApi().addEvent({
          title: 'Available',
          start: startTime,
          end: addHours(startTime, 1),
          extendedProps: {
            lyraEventType: 'bookable',
          },
        })
        startTime = addHours(startTime, 1)
      }

      calendarRef.current?.getApi().unselect()
    }, [])

    const onSelectAll = useCallback((args: DateSpanApi) => {
      if (parseISO(args.startStr).getDate() !== parseISO(args.endStr).getDate()) {
        return false
      }
      const adjustedEvent = getAdjustedEvent(args, startingRange.current)
      return !calendarRef.current
        ?.getApi()
        .getEvents()
        .some((event) =>
          eventsOverlap(
            {
              start: parseISO(event.startStr + 'Z'),
              end: parseISO(event.endStr + 'Z'),
            },
            {
              start: parseISO(adjustedEvent.start),
              end: parseISO(adjustedEvent.end),
            },
          ),
        )
    }, [])

    const onEventChange = useCallback(() => {
      const tzOffset = getTimezoneOffset(timeZone)
      onEventsChanged?.(
        calendarRef.current
          ?.getApi()
          .getEvents()
          .map((event) => ({
            startTime: subMilliseconds(parseISO(event.startStr + 'Z'), tzOffset).toISOString(),
            endTime: subMilliseconds(parseISO(event.endStr + 'Z'), tzOffset).toISOString(),
          })) ?? [],
      )
    }, [onEventsChanged, timeZone])

    const resetEvents = useCallback(() => {
      if (!calendarRef.current) {
        return
      }

      calendarRef.current?.getApi().removeAllEvents()
      const firstDay = calendarRef.current?.getApi().view.currentStart
      calendarRef.current?.getApi().addEventSource({
        events: initialEvents.map((event) => {
          const eventStart = parseISO(event.startTime)
          const zonedEventStart = utcToZonedTime(eventStart, timeZone)
          const beginningOfWeek = zonedTimeToUtc(startOfDay(setDay(zonedEventStart, 0)), timeZone)
          const difference = differenceInMinutes(zonedTimeToUtc(zonedEventStart, timeZone), beginningOfWeek)

          const startTime = addMinutes(firstDay, difference)
          const endTime = addMinutes(startTime, differenceInMinutes(parseISO(event.endTime), eventStart))
          return {
            title: 'Available',
            start: startTime,
            end: endTime,
            extendedProps: {
              lyraEventType: 'bookable',
            },
          }
        }),
      })
      onEventChange()
    }, [initialEvents, onEventChange, timeZone])

    useImperativeHandle(
      ref,
      () => ({
        reset: resetEvents,
      }),
      [resetEvents],
    )

    useEffect(() => {
      resetEvents()
    }, [resetEvents])

    return (
      <FullCalendar
        ref={calendarRef}
        headerToolbar={false}
        plugins={[timeGridWeek, interactionPlugin]}
        initialView='timeGridWeek'
        allDaySlot={false}
        dayHeaderContent={dayContentRenderer}
        selectMirror
        slotLabelContent={slotLabelContent}
        eventColor='transparent'
        expandRows={true}
        contentHeight={2000}
        stickyHeaderDates={true}
        selectable={!disableEditing}
        select={onSelect}
        timeZone={timeZone}
        eventContent={eventContent}
        eventOverlap={false}
        eventChange={onEventChange}
        eventRemove={onEventChange}
        eventAdd={onEventChange}
        eventStartEditable={!disableEditing}
        eventMouseEnter={(args) => {
          args.event.setExtendedProp('hovered', true)
        }}
        eventMouseLeave={(args) => {
          args.event.setExtendedProp('hovered', false)
        }}
        selectAllow={onSelectAll}
      />
    )
  },
)
